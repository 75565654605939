import React from 'react';
import MessageForm from '../components/MessageForm';
import {graphql, compose} from 'react-apollo';
import gql from 'graphql-tag';
import generalComposed from '../qql-shared/generalComposed';
import {SimpleBlock} from '../qql-shared/fragments';
import {SaveProjectMutation, SaveProjectMutationConfig} from '../qql-shared/mutations';
import {AllDocumentsQuery, AllDocumentsQueryConfig} from '../qql-shared/queries';
import {MESSAGE_PROJECT} from '../constants/messageTypes';

const BlocksQuery = gql`
  query allBlocks {
    allBlocks(hidden: false) {
      ...SimpleBlock
    }
  }
  ${SimpleBlock}
`;

export default compose(
  generalComposed,
  graphql(AllDocumentsQuery, AllDocumentsQueryConfig),
  graphql(SaveProjectMutation, SaveProjectMutationConfig),
  graphql(BlocksQuery)
)(props => <MessageForm {...props} type={MESSAGE_PROJECT} />);
