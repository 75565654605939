import React from 'react';
import PropTypes from 'prop-types';
import DropzoneComponent from 'react-dropzone-component';
import 'react-dropzone-component/styles/filepicker.css';
import 'dropzone/dist/min/dropzone.min.css';
import config from '../../config';

const accept = (name, fileTypes) => name.match(new RegExp('.*\\.(' + fileTypes.join('|') + ')$', 'i'));

const MediaUploader = ({fileTypes, onUploadDone}) => (
  <DropzoneComponent
    config={{
      iconFiletypes: fileTypes,
      showFiletypeIcon: true,
      postUrl: config.apiUrl + '/upload',
    }}
    eventHandlers={{
      success: (response, data) => onUploadDone(data),
      //Todo: handle error
    }}
    djsConfig={{
      headers: {authorization: config.getAuthHeader()},
      autoProcessQueue: true,
      accept: ({name}, done) => (accept(name, fileTypes) ? done() : done('Dieser Dateityp wird nicht unterstützt.')),
      // set maxFilesize to 5MB
      maxFilesize: 5,
    }}
  />
);

MediaUploader.propTypes = {
  onUploadDone: PropTypes.func.isRequired,
  fileTypes: PropTypes.array.isRequired,
};

export default MediaUploader;
