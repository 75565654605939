import React from 'react';
import MessageForm from '../components/MessageForm';
import {graphql, compose} from 'react-apollo';
import gql from 'graphql-tag';
import generalComposed from '../qql-shared/generalComposed';
import {SaveMessageMutation, SaveMessageMutationConfig} from '../qql-shared/mutations';
import {ReceiversQueryConfig, AllProjectsQuery} from '../qql-shared/queries';
import {SimpleBlock} from '../qql-shared/fragments';
import {MESSAGE_MESSAGE} from '../constants/messageTypes';

const ReceiversQuery = gql`
  query receivers {
    allUsers {
      _id
      email
      firstName
      lastName
    }
    allBlocks(hidden: false) {
      ...SimpleBlock
    }
  }
  ${SimpleBlock}
`;

export default compose(
  generalComposed,
  graphql(SaveMessageMutation, SaveMessageMutationConfig),
  graphql(AllProjectsQuery),
  graphql(ReceiversQuery, ReceiversQueryConfig)
)(props => <MessageForm {...props} type={MESSAGE_MESSAGE} />);
