import styled from 'styled-components';
import {isDev} from '../../helpers/devHelper';

export const HeadlineH1 = styled.h1`
  font-size: 30px;
  font-weight: lighter;
  ${isDev()
    ? `
  &:after {
    margin: 300px;
    font-weight: bold;
    content: "! Test Backend !";
  }
  `
    : ''};
`;
export const HeadlineH2 = styled.h2`
  font-size: 25px;
  font-weight: lighter;
`;
