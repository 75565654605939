import RegionForm from '../components/RegionForm';
import {graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {Region} from '../qql-shared/fragments';
import {SaveRegionMutation, SaveRegionMutationConfig} from '../qql-shared/mutations';

const RegionQuery = gql`
  query region($_id:String!) {
    region(_id:$_id) {
      ...Region
    }
  }
  ${Region}
`;

const RegionQueryConfig = {
  options: ({match}) => ({variables: {_id: match.params.id}})
};

export default graphql(SaveRegionMutation, SaveRegionMutationConfig)(
  graphql(RegionQuery, RegionQueryConfig)(RegionForm)
);
