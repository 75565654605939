import React, {useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import {Fragment} from 'react';
import {TextField} from '@material-ui/core';
import {SortablePreview} from './SortablePreview';
import RemoveIcon from '@material-ui/icons/Delete';
import {DeleteItemIconWrapper} from './media-picker/DeleteItemIconWrapper';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import MediaPicker from '../components/media-picker/MediaPicker';
import MediaUploader from './media-picker/MediaUploader';

const Wrapper = styled.div`
  margin-top: 20px;
`;

const CommentHeader = styled.div`
  margin-top: 20px;
  font-size: 20px;
`;

const CommentText = styled.div`
  white-space: pre-wrap;
`;

const CommentImages = styled.div`
  margin-top: 20px;
  img {
    margin-bottom: 10px;
  }
`;

const ActionRow = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & button {
    margin-bottom: 10px;
  }
`;

const SelectedWrapper = styled.div`
  position: relative;
  margin: 10px;
`;

const Image = styled(LazyLoadImage)`
  width: 150px;
  height: 120px;
`;

const StyledMediaPicker = styled(MediaPicker)`
  margin: 10px 0;
`;

const renderImages = images => {
  return images.map((image, idx) => {
    return <img src={image.url} alt={image.title} key={idx} />;
  });
};

const renderImage = ({url, title}) => <Image src={url} effect='opacity' alt={title} />;

const Comment = ({comment, notLast, imagesQuery, updateComment}) => {
  const {_id, text, images, createdAt, isCreateUser} = comment;
  const {firstName, lastName} = comment.createUser;

  const [edit, setEdit] = useState(false);
  const [editState, setEditState] = useState({text: text || '', images: images || []});

  const editComment = () => {
    setEditState({text, images: images || []});
    setEdit(true);
  };

  const abortEditComment = () => {
    setEdit(false);
    setEditState({text, images: images || []});
  };

  const saveEditComment = () => {
    updateComment(_id, editState.text, editState.images);
    setEdit(false);
    setEditState({text, images: images || []});
  };

  const confirmRemove = id => {
    setEditState(prev => ({...prev, images: prev.images.filter(item => item._id !== id)}));
  };

  const handleUploadedImage = image =>
    imagesQuery.updateQuery(({allImagesPaginated, ...prev}) => ({
      ...prev,
      allImagesPaginated: [{...image, __typename: 'Media'}, ...allImagesPaginated],
    }));

  const renderSelectedImage = ({url, title, _id}) => (
    <SelectedWrapper key={_id}>
      <DeleteItemIconWrapper onClick={() => confirmRemove(_id)}>
        <RemoveIcon />
      </DeleteItemIconWrapper>
      <Image src={url} alt={title} />
    </SelectedWrapper>
  );

  return (
    <div>
      <CommentHeader>
        {firstName} {lastName} am {moment(createdAt).format('DD.MM.YYYY')}
      </CommentHeader>
      {edit && (
        <Fragment>
          <TextField
            label='Kommentar'
            type='text'
            multiline
            fullWidth
            rows={1}
            value={editState.text || ''}
            onChange={({target: {value}}) => setEditState(prev => ({...prev, text: value}))}
          />
          <StyledMediaPicker
            title='Bilder auswählen'
            items={imagesQuery.allImagesPaginated || []}
            multiple
            selectedItems={editState.images}
            onSelected={images => setEditState(prev => ({...prev, images}))}
            renderItem={renderImage}>
            <MediaUploader onUploadDone={handleUploadedImage} fileTypes={['jpg', 'png']} />
          </StyledMediaPicker>
          <SortablePreview
            id={'images'}
            data={editState.images}
            renderItem={renderSelectedImage}
            onRemove={confirmRemove}
            onChange={images => setEditState(prev => ({...prev, images}))}
          />
          {isCreateUser && (
            <ActionRow>
              <Button variant='contained' disabled={!editState.text} onClick={saveEditComment}>
                Kommentar speichern
              </Button>
              <Button variant='contained' onClick={abortEditComment}>
                bearbeiten abbrechen
              </Button>
            </ActionRow>
          )}
        </Fragment>
      )}
      {!edit && (
        <Fragment>
          <CommentText>{text}</CommentText>
          <CommentImages>{renderImages(images)}</CommentImages>
          {isCreateUser && (
            <ActionRow>
              <Button variant='contained' onClick={editComment}>
                Kommentar bearbeiten
              </Button>
            </ActionRow>
          )}
        </Fragment>
      )}
      {notLast && <hr />}
    </div>
  );
};

export default ({comments, imagesQuery, updateComment}) => (
  <Wrapper>
    {comments && comments.length > 0
      ? comments.map((comment, idx) => (
          <Comment
            key={idx}
            notLast={idx !== comments.length - 1}
            imagesQuery={imagesQuery}
            updateComment={updateComment}
            comment={comment}
          />
        ))
      : 'Noch keine Kommentare vorhanden.'}
  </Wrapper>
);
