import UserForm from '../components/UserForm';
import {graphql} from 'react-apollo';
import {SimpleBlock} from '../qql-shared/fragments';
import {SaveUserMutation, SaveUserMutationConfig} from '../qql-shared/mutations';
import gql from 'graphql-tag';

const UserQuery = gql`
  query allBlocks {
    allBlocks(hidden: false) {
      ...SimpleBlock
    }
  }
  ${SimpleBlock}
`;

export default graphql(SaveUserMutation, SaveUserMutationConfig)(graphql(UserQuery)(UserForm));
