import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {HeadlineH1} from './text/Headlines';
import ActionButtonRow from '../components/ActionButtonRow';
import {Centered500} from './container/CenteredContainer';
import styled from 'styled-components';
import ErrorMessage from './ErrorMessage';
import convertErrors from '../helpers/errorHelper';

const Success = styled.div`margin-bottom: 30px;`;

export default class extends Component {
  state = {password: '', repeatPassword: '', changed: false};

  render() {
    let {password, repeatPassword, changed, errors} = this.state;
    return (
      <Centered500>
        <ErrorMessage errors={errors} clearErrors={() => this.setState({errors: []})} />
        <HeadlineH1>Passwort ändern</HeadlineH1>
        {changed
          ? <Success>
              Dein Passwort wurde erfolgreich geändert.
              <br />
              Du kannst dich nun wieder in der RIVA HOME App anmelden.
            </Success>
          : <div>
              Hier kannst du dein Passwort für RIVA HOME ändern.<br />
              Anschließend kannst du dich wieder in der App anmelden.
              <TextField
                type="password"
                fullWidth
                label="Passwort"
                value={password}
                autoFocus
                onChange={({target: {value}}) => this.setState({password: value})}
              />
              <TextField
                type="password"
                fullWidth
                label="Passwort wiederholen"
                value={repeatPassword}
                onChange={({target: {value}}) => this.setState({repeatPassword: value})}
              />
              <ActionButtonRow>
                <div />
                <Button
                  color="primary"
                  onClick={() =>
                    this.props.changePassword(password).then(() => this.setState({changed: true})).catch(rawErrors => {
                      console.log('catch');
                      let errors = convertErrors(rawErrors);
                      this.setState({errors});
                      console.log('there was an error sending the query', errors);
                    })}
                  disabled={!(password.length || repeatPassword.length) || password !== repeatPassword}
                >
                  Passwort ändern
                </Button>
              </ActionButtonRow>
            </div>}
      </Centered500>
    );
  }
}
