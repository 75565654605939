import {createGlobalStyle} from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
  }
  a {
    text-decoration: none;
  }
  a, a:hover {
    color: white;
  }
  a:focus, a:hover {
    text-decoration: underline;
}
`;

export default GlobalStyles
