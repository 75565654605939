import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import RemoveIcon from '@material-ui/icons/Delete';
import {DatePicker} from 'material-ui-pickers';
import {STATUS_ENTRY_IMAGES} from '../constants/stateKeys';
import moment from 'moment';

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StatusEntryWrapper = styled(Row)`
  ${({divider}) => divider && 'border-bottom: 1px solid lightgray;'}
  flex-wrap: wrap;
  padding: 10px 0;
`;

const StyledRemoveIcon = styled(RemoveIcon)`
  font-size: 26px;
  min-width: 26px;
  cursor: pointer;
`;

const TextFieldWrapper = styled.div`
  margin: 16px 0 0 10px;
  width: 100%;
`;

const AddButton = styled(Button)`
  margin-top: 10px;
`;

const DoneWrapper = styled.div`
  margin-left: 16px;
`;

const ButtonWrapper = styled.div`
  flex-shrink: 0;
  margin-left: 10px;
`;

const NEW_STATUS_ENTRY = {done: false, text: '', date: null, images: [], autoFocus: true};

export default ({statusEntries, onChange, addStatusEntry, imagePicker, imagePreview}) => (
  <div>
    <AddButton variant='contained' onClick={() => onChange([NEW_STATUS_ENTRY, ...statusEntries])}>
      Eintrag hinzufügen
    </AddButton>
    {statusEntries.map(({done, text, date, autoFocus}, i, {length}) => (
      <StatusEntryWrapper key={i} divider={i !== length - 1}>
        <Row>
          <StyledRemoveIcon onClick={() => onChange(statusEntries.filter((_, index) => i !== index))} />
          <DoneWrapper>
            <Checkbox
              checked={done}
              onChange={() =>
                onChange(statusEntries.map((entry, index) => (index === i ? {...entry, done: !entry.done} : entry)))
              }
            />
          </DoneWrapper>
          <DatePicker
            style={{width: 300}}
            label='Datum'
            value={date ? new Date(date) : null}
            cancelLabel='Abbrechen'
            okLabel='OK'
            format='L'
            helperText={undefined}
            error={undefined}
            onChange={date => {
              onChange(
                statusEntries.map((entry, index) =>
                  index === i
                    ? {
                        ...entry,
                        date: moment(date)
                          .startOf('d')
                          .toISOString(),
                      }
                    : entry
                )
              );
            }}
          />
          <TextFieldWrapper>
            <TextField
              id={'entry' + i}
              placeholder='Fortschritts-Eintrag'
              autoFocus={autoFocus}
              type='text'
              fullWidth
              value={text}
              onChange={({target: {value}}) =>
                onChange(statusEntries.map((entry, index) => (index === i ? {...entry, text: value} : entry)))
              }
            />
          </TextFieldWrapper>

          <ButtonWrapper>{imagePicker(STATUS_ENTRY_IMAGES, i)}</ButtonWrapper>
        </Row>

        {imagePreview(STATUS_ENTRY_IMAGES, i)}
      </StatusEntryWrapper>
    ))}
  </div>
);
