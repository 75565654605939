import Blocks from '../components/Blocks';
import {graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {ListBlock} from '../qql-shared/fragments';

const BlocksQuery = gql`
  query allBlocks($hidden: Boolean!) {
    allBlocks(hidden: $hidden) {
      ...ListBlock
    }
  }
  ${ListBlock}
`;

export default graphql(BlocksQuery, {
  options: {
    variables: {hidden: false},
    fetchPolicy: 'network-only',
  },
})(Blocks);
