import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import constants from '../parameters/constants';

const ErrorContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 3px solid red;
  background: ${constants.primaryColor};
  z-index: 1000;
  color: white;
  padding: 20px;
  padding-bottom: 40px;
  font-size: 20px;
`;

export default class extends Component {
  static state = {
    timeout: false
  };

  timeout = undefined;

  static propTypes = {
    errors: PropTypes.array,
    clearErrors: PropTypes.func.isRequired
  };

  clearTimeout = () => this.timeout && clearTimeout(this.timeout);

  componentWillReceiveProps(newProps) {
    if (newProps.errors && newProps.errors.length > 0) {
      this.clearTimeout();
      this.timeout = setTimeout(() => {
        this.props.clearErrors();
      }, 4000);
    }
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  render() {
    const {errors} = this.props;
    if (!errors || errors.length === 0) {
      return null;
    }
    return (
      <ErrorContainer>
        <h2>Fehler:</h2>
        {errors.map((error, i) => {
          let err = error;
          if (error.message) {
            err = error.message;
          }
          return (
            <div key={'error_' + i}>
              {err}
            </div>
          );
        })}
      </ErrorContainer>
    );
  }
}
