import styled from 'styled-components';
import constants from '../../parameters/constants';

export const FormRow = styled.div`
  padding: 10px 15px;
`;

export const NewButton = styled.div`
  position: absolute;
  padding: 0 10px;
  right: 100px;
  top: 0;
  height: 40px;
  font-size: 25px;
  line-height: 40px;
  background: ${constants.primaryColor};
  a,
  a:hover,
  a:visited {
    color: white;
  }
`;

export const AdditionalButton = styled(NewButton)`
  right: 310px;
`;
