import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const Wrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const LoadingIndicator = ({size}) => (
  <Wrapper>
    <CircularProgress {...size} />
  </Wrapper>
);

LoadingIndicator.defaultProps = {
  size: 80,
};

export default LoadingIndicator;
