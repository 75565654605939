import {graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {SimpleMessage} from '../qql-shared/fragments';
import Jobs from '../components/Jobs';

const JobsQuery = gql`
  query allJobs($hidden: Boolean!, $searchStr: String, $searchStrReceiver: String, $toEditors: Boolean) {
    allJobs(hidden: $hidden, searchStr: $searchStr, searchStrReceiver: $searchStrReceiver, toEditors: $toEditors) {
      ...SimpleMessage
      endDateStr
    }
  }
  ${SimpleMessage}
`;

export default graphql(JobsQuery, {
  options: {
    variables: {hidden: false, searchStr: '', searchStrReceiver: '', toEditors: false},
    fetchPolicy: 'network-only',
  },
})(Jobs);
