import {Link} from 'react-router-dom';
import React from 'react';
import moment from 'moment';

import {AdditionalButton, NewButton} from './form/FormElements';
import {HeadlineH1} from './text/Headlines';
import EntityTable from './table/EntityTable';
import config from '../config';

const _tableConfig = () => {
  return {
    maxRows: 25,
    cols: [
      {
        header: 'Name',
        valueKey: '*',
        method: ({firstName, lastName}) => firstName + ' ' + lastName,
        width: '15%',
      },
      {
        header: 'Email',
        valueKey: 'email',
        method: email => email,
        width: '15%',
      },
      {
        header: 'Telefonnummer',
        valueKey: 'phone',
        method: phone => phone,
        width: '15%',
      },
      {
        header: 'Wohnanlage',
        valueKey: 'blocks',
        method: blocks => blocks.map(({name}) => name).join(', '),
        width: '15%',
      },
      {
        header: 'Regionen',
        valueKey: 'regions',
        method: regions => (regions && regions.length ? regions.map(({name}) => name).join(', ') : 'alle Regionen'),
        width: '15%',
      },
      {
        header: 'Role',
        valueKey: 'role',
        method: role => role,
        width: '10%',
      },
      {
        header: 'Registriert',
        valueKey: 'createDateStr',
        method: createDateStr => moment(createDateStr).format('LL'),
        sortKeyMethod: createDateStr => new Date(createDateStr).getTime(),
        width: '15%',
      },
    ],
  };
};

export default ({data, history}) => {
  return (
    <div>
      <HeadlineH1>Benutzer</HeadlineH1>
      <NewButton>
        <Link to='/new/user'>Neuer Benutzer</Link>
      </NewButton>
      <AdditionalButton>
        <a
          href={`${config.apiUrl}/user-export?authToken=${config.getPlainAuthToken()}`}
          target='_blank'
          rel='noopener noreferrer'>
          Benutzer exportieren
        </a>
      </AdditionalButton>
      <EntityTable
        tableConfig={_tableConfig()}
        data={data.allUsers || []}
        msg={'Noch keine Benutzer erstellt'}
        onRowClicked={row => history.push('/users/' + row._id)}
      />
    </div>
  );
};
