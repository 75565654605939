import styled from 'styled-components';

export const Centered300 = styled.div`
  margin:100px auto;
  width:300px;
  background: rgba(255,255,255,1);
`;

export const Centered500 = styled.div`
  margin: 0 auto;
  width:500px;
  background: white;
  padding: 10px 20px;
`;

export const Centered1000 = styled.div`
  position: relative;
  margin: 40px auto;
  padding: 10px 20px;
  min-width:1000px;
  max-width: 1400px;
  background: rgba(255,255,255,1);
`;
