import styled from 'styled-components';

export default styled.div`
  border-top: 1px solid lightgrey;
  margin: 20px -20px 0;
  padding: 10px 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
