import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import {useDebounce} from 'use-debounce/lib';
import {Checkbox} from '@material-ui/core';

function EntityTablePaginationHead(props) {
  return (
    <TableHead>
      <TableRow>
        {props.multipleActions && <TableCell />}
        {props.cols.map(
          ({header, width}, idx) => (
            <TableCell key={idx} style={{width}}>
              <Tooltip title='Sort' placement='bottom-start' enterDelay={300}>
                <TableSortLabel>{header}</TableSortLabel>
              </Tooltip>
            </TableCell>
          ),
          this
        )}
      </TableRow>
    </TableHead>
  );
}

const ColsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    header: PropTypes.string,
    valueKey: PropTypes.string,
    method: PropTypes.func,
    width: PropTypes.any,
    sortKeyMethod: PropTypes.func,
    filterString: PropTypes.func,
  })
);

EntityTablePaginationHead.propTypes = {
  cols: ColsPropType,
};

const Wrapper = styled.div`
  width: 100%;
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const Search = styled(TextField)`
  width: 300px;
`;
const SearchSpacer = styled.div`
  width: 40px;
`;

const InlineDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const CheckboxLabel = styled.div`
  padding-top: 15px;
  width: 120px;
`;

const StyledCheckbox = styled(Checkbox)`
  display: inline-block
`;

const CheckboxSpacer = styled.div`
  width: 40px;
`;

const MultipleActionWrapper = styled.div`
  margin-left: 20px;
`;

const MultipleActionItem = styled.div`
  margin-left: 10px;
  cursor: pointer;
`;

function EntityTablePagination(props) {
  const [filter, setFilter] = React.useState('');
  const [secondFilter, setSecondFilter] = React.useState('');
  const [rows, setRows] = React.useState([]);
  const [filterValue] = useDebounce(filter, 600, {leading: true});
  const [secondFilterValue] = useDebounce(secondFilter, 600, {leading: true});
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toEditors, setToEditors] = React.useState(false);

  let {tableConfig, totalRows, currentPage, perPage, onRowClicked, multipleActions} = props;

  function computeRows(cols, data) {
    const computedRows = data.reduce((computedRows, item) => {
      const computedCols = cols.map(conf => {
        let value;
        if (conf.valueKey === '*') {
          value = item;
        } else {
          value = item[conf.valueKey];
        }
        const computed = conf.method(value);
        let sortKey = conf.sortKeyMethod ? conf.sortKeyMethod(value) : computed;
        if (sortKey) sortKey = sortKey.toString().toUpperCase();
        const filterKey = conf.filterString ? conf.filterString(value) : computed;
        return {computed, sortKey, filterKey};
      });
      computedRows.push({computedCols, item});
      return computedRows;
    }, []);
    return computedRows;
  }

  useEffect(() => {
    setRows(computeRows(tableConfig.cols, props.data));
  }, [props.data, tableConfig.cols]);

  useEffect(() => {
    typeof props.onSearch === 'function' && props.onSearch(filterValue);
  }, [filterValue]);

  useEffect(() => {
    typeof props.onSecondSearch === 'function' && props.onSecondSearch(secondFilterValue);
  }, [secondFilterValue]);

  useEffect(() => {
    typeof props.checkBox === 'function' && props.checkBox(toEditors);
  }, [toEditors]);

  function handleFilterChange(event) {
    event.preventDefault();
    setFilter(event.target.value);
  }

  function secondHandleFilterChange(event) {
    event.preventDefault();
    setSecondFilter(event.target.value);
  }

  function handleToEditorsChange(event) {
    setToEditors(!toEditors);
  }

  function handleChangePage(event, newPage) {
    typeof props.onPageChanged === 'function' && props.onPageChanged(newPage);
  }

  function handleChangeRowsPerPage(event) {
    typeof props.onPerPageChanged === 'function' && props.onPerPageChanged(event.target.value);
  }

  function isRowSelected(row) {
    return selectedRows.findIndex(ele => ele._id === row._id) !== -1;
  }

  function handleRowSelect(row, checked) {
    if (isRowSelected(row)) {
      setSelectedRows(value => [...value.filter(ele => ele._id !== row._id)]);
    } else {
      setSelectedRows(value => [...value, row]);
    }
  }

  return (
    <Wrapper>
      <Toolbar>
        <Spacer />
        {props.secondSearch === true && 
          <InlineDiv>
            <StyledCheckbox type='checkbox' name='toEditors' onChange={handleToEditorsChange}/>
            <CheckboxLabel>An Riva Betreuer</CheckboxLabel>
          </InlineDiv>
        }
        {props.secondSearch === true && <CheckboxSpacer/>}
        {props.secondSearch && !toEditors ? <Search type='text' name='search' placeholder='Empfänger' onChange={secondHandleFilterChange} value={secondFilter} /> : 
          <Search type='text' name='search' placeholder='Empfänger' onChange={secondHandleFilterChange} value={secondFilter} disabled/>
        }
        {props.secondSearch === true &&<SearchSpacer/>}
        <Search type='text' name='search' placeholder='Suchen' onChange={handleFilterChange} value={filter} />
        {multipleActions && selectedRows.length > 0 && (
          <MultipleActionWrapper>
            {multipleActions.map((item, idx) => (
              <MultipleActionItem
                key={idx}
                onClick={() => {
                  item.onClick(selectedRows);
                  setSelectedRows([]);
                }}>
                {item.content()}
              </MultipleActionItem>
            ))}
          </MultipleActionWrapper>
        )}
      </Toolbar>
      <Table aria-labelledby='tableTitle'>
        <EntityTablePaginationHead multipleActions={multipleActions} cols={tableConfig.cols} />
        <TableBody>
          {rows.map(({item, computedCols}, idx) => {
            const selected = isRowSelected(item);
            return (
              <TableRow hover tabIndex={-1} key={idx} selected={selected}>
                {multipleActions && (
                  <TableCell component='th' scope='row'>
                    <Checkbox checked={selected} onClick={() => handleRowSelect(item, selected)} />
                  </TableCell>
                )}
                {tableConfig.cols.map((conf, colIdx) => {
                  const {computed} = computedCols[colIdx];
                  return (
                    <TableCell key={idx + '_' + colIdx} component='th' scope='row' onClick={() => onRowClicked(item)}>
                      {computed}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={totalRows}
        rowsPerPage={perPage}
        page={currentPage}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        labelRowsPerPage='Zeilen pro Seite:'
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Wrapper>
  );
}

EntityTablePagination.propTypes = {
  data: PropTypes.array,
  fillEmptyRows: PropTypes.bool,
  tableConfig: PropTypes.shape({
    maxRows: PropTypes.number,
    cols: ColsPropType,
  }),
  secondSearch: PropTypes.bool,
  onRowClicked: PropTypes.func,
};

export default EntityTablePagination;
