import React, {Component} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import {MESSAGE_DEFECT, MESSAGE_NEWS, MESSAGE_POLL} from '../constants/messageTypes';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import AutoComplete from './AutoComplete';
import {Select, MenuItem} from '@material-ui/core';

const _getSelected = ({message, regions, blocks, users}) => {
  if (message) {
    if (message.toAll) {
      return 'all';
    } else if (message.blocks.length !== 0 && blocks) {
      return 'block';
    } else if (message.receiver !== null && users) {
      return 'receiver';
    } else if (message.region !== null && regions) {
      return 'region';
    }
  }
  return 'block';
};

const _sort = (key, a, b) => (a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0);

class ReceiverForm extends Component {
  constructor(props) {
    super(props);
    const selected = _getSelected(props);
    this.state = {selected: selected};
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.message && nextProps.message._id !== this.props.message._id) {
      this.setState({selected: _getSelected(nextProps)});
    }
  }

  _renderSelect = () => {
    const {blocks, users, regions, type} = this.props;

    if (!blocks && !users && !regions) {
      return null;
    }
    return (
      <FormGroup row>
        {(type === MESSAGE_NEWS || type === MESSAGE_POLL) && (
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.selected === 'all'}
                onChange={() => {
                  this.setState({selected: 'all', toAll: true});
                  this._updateParent(null, [], null, true);
                }}
              />
            }
            label='An Alle'
          />
        )}
        {blocks && (
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.selected === 'block'}
                onChange={() => {
                  this.setState({selected: 'block', toAll: false});
                }}
              />
            }
            label='Wohnanlage'
          />
        )}
        {users && (
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.selected === 'receiver'}
                onChange={() => {
                  this.setState({selected: 'receiver', toAll: false});
                }}
              />
            }
            label='Benutzer'
          />
        )}
        {regions && (
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.selected === 'region'}
                onChange={() => {
                  this.setState({selected: 'region', toAll: false});
                }}
              />
            }
            label='Region'
          />
        )}
      </FormGroup>
    );
  };

  _renderBlocksSelect = () => {
    const {blocks} = this.props;
    if (blocks && this.state.selected === 'block') {
      if (this.props.multipleBlocks) {
        return (
          <>
            <Select
              value={this.props.message.blocks.length === 0 ? ['none'] : this.props.message.blocks}
              multiple
              autoWidth
              onChange={({target: {value}}) =>
                this._updateParent(null, value.filter(item => item !== 'none'), null, false)
              }
              inputProps={{
                name: 'role-select',
                id: 'block-auto-complete',
              }}>
              <MenuItem value='none' disabled>
                Wohnanlagen auswählen
              </MenuItem>
              {blocks
                .slice(0)
                .sort(_sort.bind(undefined, 'name'))
                .map(({_id, name}) => (
                  <MenuItem key={_id} value={_id}>
                    {name || ''}
                  </MenuItem>
                ))}
            </Select>
          </>
        );
      }
      return (
        <AutoComplete
          id='block-auto-complete'
          label='Wohnanlage auswählen'
          availableItems={blocks.slice(0).sort(_sort.bind(undefined, 'name'))}
          getId={item => item && item._id}
          getLabel={item => (item && item.name) || ''}
          onChange={item => this._updateParent(null, [item], null, false)}
          selectedItem={this.props.message.blocks[0]}
        />
      );
    }
  };
  _renderRegionSelect = () => {
    const {regions} = this.props;
    if (regions && this.state.selected === 'region') {
      return (
        <AutoComplete
          id='region-auto-complete'
          label='Region auswählen'
          availableItems={regions.slice(0).sort(_sort.bind(undefined, 'name'))}
          getId={item => item && item._id}
          getLabel={item => (item && item.name) || ''}
          onChange={item => this._updateParent(null, [], item, false)}
          selectedItem={this.props.message.region}
        />
      );
    }
  };
  _renderUserSelect = () => {
    const {users} = this.props;
    if (users && this.state.selected === 'receiver') {
      const modifiedUsers = users.map(user => {
        const modUser = {};
        modUser.fullName = user.firstName + ' ' + user.lastName;
        modUser._id = user._id;
        return modUser;
      });
      return (
        <AutoComplete
          id='user-auto-complete'
          label='Benutzer auswählen'
          availableItems={modifiedUsers.sort(_sort.bind(undefined, 'fullName'))}
          getId={item => item && item._id}
          getLabel={item => (item && item.fullName) || ''}
          onChange={item => this._updateParent(item, [], null, false)}
          selectedItem={this.props.message.receiver}
        />
      );
    }
  };
  _updateParent = (receiver, blocks, region, toAll) =>
    this.props.update({
      receiver,
      blocks,
      region,
      toAll,
      toEditor: this.props.type === MESSAGE_DEFECT ? false : this.props.message.toEditor,
    });
  render() {
    return (
      <div>
        {this._renderSelect()}
        {this._renderBlocksSelect()}
        {this._renderRegionSelect()}
        {this._renderUserSelect()}
      </div>
    );
  }
}
export default ReceiverForm;
