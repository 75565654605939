import React from 'react';
import MessageForm from '../components/MessageForm';
import {graphql, compose} from 'react-apollo';
import generalComposed from '../qql-shared/generalComposed';
import {SaveJobMutation, SaveJobMutationConfig} from '../qql-shared/mutations';
import {
  ReceiversQueryConfig,
  AllDocumentsQuery,
  AllDocumentsQueryConfig,
  JobReceiversQuery
} from '../qql-shared/queries';
import {MESSAGE_JOB} from '../constants/messageTypes';

export default compose(
  generalComposed,
  graphql(AllDocumentsQuery, AllDocumentsQueryConfig),
  graphql(SaveJobMutation, SaveJobMutationConfig),
  graphql(JobReceiversQuery, ReceiversQueryConfig)
)(props => <MessageForm {...props} type={MESSAGE_JOB} />);
