import Regions from '../components/Regions';
import {graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {Region} from '../qql-shared/fragments';

const RegionsQuery = gql`
  query allRegions {
    allRegions {
      ...Region
    }
  }
  ${Region}
`;

export default graphql(RegionsQuery)(Regions);
