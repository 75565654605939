import gql from 'graphql-tag';
import {User, SimpleMessage, Block, Region, Comment} from './fragments';

export const SaveUserMutation = gql`
  mutation saveUser(
    $_id: String
    $active: Boolean!
    $email: String!
    $password: String
    $phone: String
    $firstName: String!
    $lastName: String!
    $role: String!
    $blocks: [String!]
  ) {
    saveUser(
      _id: $_id
      active: $active
      email: $email
      password: $password
      phone: $phone
      firstName: $firstName
      lastName: $lastName
      role: $role
      blocks: $blocks
    ) {
      ...User
    }
  }
  ${User}
`;

export const SaveUserMutationConfig = {
  props: ({
    mutate,
    ownProps: {
      match: {
        params: {id: _id},
      },
    },
  }) => ({
    save: user =>
      mutate({
        variables: {_id, ...user},
        updateQueries: {
          allUsers: (
            {allUsers, ...prev},
            {
              mutationResult: {
                data: {saveUser},
              },
            }
          ) =>
            _id
              ? {...prev, allUsers: allUsers.map(user => (user._id === _id ? saveUser : user))}
              : {...prev, allUsers: [saveUser, ...allUsers]},
        },
      }),
  }),
};

export const SaveBlockMutation = gql`
  mutation saveBlock(
    $_id: String
    $name: String!
    $description: String
    $street: String!
    $number: String!
    $zipCode: String!
    $city: String!
    $region: String!
  ) {
    saveBlock(
      _id: $_id
      name: $name
      description: $description
      street: $street
      number: $number
      zipCode: $zipCode
      city: $city
      region: $region
    ) {
      ...Block
    }
  }
  ${Block}
`;

export const SaveBlockMutationConfig = {
  props: ({
    mutate,
    ownProps: {
      match: {
        params: {id: _id},
      },
    },
  }) => ({
    save: block =>
      mutate({
        variables: {_id, ...block},
        updateQueries: {
          allBlocks: (
            {allBlocks, ...prev},
            {
              mutationResult: {
                data: {saveBlock},
              },
            }
          ) =>
            _id
              ? {...prev, allBlocks: allBlocks.map(block => (block._id === _id ? saveBlock : block))}
              : {...prev, allBlocks: [saveBlock, ...allBlocks]},
        },
      }),
  }),
};

export const SaveRegionMutation = gql`
  mutation saveRegion($_id: String, $name: String!, $description: String) {
    saveRegion(_id: $_id, name: $name, description: $description) {
      ...Region
    }
  }
  ${Region}
`;

export const SaveRegionMutationConfig = {
  props: ({
    mutate,
    ownProps: {
      match: {
        params: {id: _id},
      },
    },
  }) => ({
    save: region =>
      mutate({
        variables: {_id, ...region},
        updateQueries: {
          allRegions: (
            {allRegions, ...prev},
            {
              mutationResult: {
                data: {saveRegion},
              },
            }
          ) =>
            _id
              ? {...prev, allRegions: allRegions.map(region => (region._id === _id ? saveRegion : region))}
              : {...prev, allRegions: [saveRegion, ...allRegions]},
        },
      }),
  }),
};

const SAVE_MESSAGE_PROP_NAME = 'save';

export const SaveMessageMutation = gql`
  mutation saveMessage(
    $_id: String
    $title: String!
    $text: String!
    $textRich: String!
    $region: String
    $commentsArePrivate: Boolean
    $blocks: [String]
    $receiver: String
    $images: [String!]
    $documents: [String!]
    $publishDateStr: String
    $sendPush: Boolean
    $pushTitle: String
    $pushMessage: String
    $linkedProject: String
  ) {
    saveMessage(
      _id: $_id
      title: $title
      text: $text
      textRich: $textRich
      region: $region
      commentsArePrivate: $commentsArePrivate
      blocks: $blocks
      receiver: $receiver
      images: $images
      documents: $documents
      publishDateStr: $publishDateStr
      sendPush: $sendPush
      pushTitle: $pushTitle
      pushMessage: $pushMessage
      linkedProject: $linkedProject
    ) {
      ...SimpleMessage
    }
  }
  ${SimpleMessage}
`;

export const SaveMessageMutationConfig = {
  props: ({
    mutate,
    ownProps: {
      match: {
        params: {id: _id},
      },
    },
  }) => ({
    [SAVE_MESSAGE_PROP_NAME]: message =>
      mutate({
        variables: {_id, ...message},
        updateQueries: {
          allMessages: (
            {allMessages, ...prev},
            {
              mutationResult: {
                data: {saveMessage},
              },
            }
          ) =>
            _id
              ? {...prev, allMessages: allMessages.map(message => (message._id === _id ? saveMessage : message))}
              : {...prev, allMessages: [saveMessage, ...allMessages]},
        },
      }),
  }),
};

export const SaveMessageDocumentMutation = gql`
  mutation saveMessageDocument(
    $_id: String
    $title: String!
    $text: String!
    $textRich: String!
    $receiver: String
    $images: [String!]
    $documents: [String!]
    $publishDateStr: String
    $commentsArePrivate: Boolean
    $sendPush: Boolean
    $pushTitle: String
    $pushMessage: String
    $linkedProject: String
  ) {
    saveMessageDocument(
      _id: $_id
      title: $title
      text: $text
      textRich: $textRich
      receiver: $receiver
      images: $images
      documents: $documents
      publishDateStr: $publishDateStr
      commentsArePrivate: $commentsArePrivate
      sendPush: $sendPush
      pushTitle: $pushTitle
      pushMessage: $pushMessage
      linkedProject: $linkedProject
    ) {
      ...SimpleMessage
    }
  }
  ${SimpleMessage}
`;

export const SaveMessageDocumentMutationConfig = {
  props: ({
    mutate,
    ownProps: {
      match: {
        params: {id: _id},
      },
    },
  }) => ({
    save: document =>
      mutate({
        variables: {_id, ...document},
        updateQueries: {
          allMessageDocuments: (
            {allMessageDocuments, ...prev},
            {
              mutationResult: {
                data: {saveMessageDocument},
              },
            }
          ) =>
            _id
              ? {
                  ...prev,
                  allMessageDocuments: allMessageDocuments.map(document =>
                    document._id === _id ? saveMessageDocument : document
                  ),
                }
              : {...prev, allMessageDocuments: [saveMessageDocument, ...allMessageDocuments]},
        },
      }),
  }),
};

export const SaveNewsMutation = gql`
  mutation saveNews(
    $_id: String
    $title: String!
    $text: String!
    $textRich: String!
    $youTubeId: String
    $region: String
    $toAll: Boolean
    $block: String
    $images: [String!]
    $documents: [String!]
    $publishDateStr: String
    $sendPush: Boolean
    $pushTitle: String
    $pushMessage: String
    $linkedProject: String
  ) {
    saveNews(
      _id: $_id
      title: $title
      text: $text
      textRich: $textRich
      youTubeId: $youTubeId
      region: $region
      toAll: $toAll
      block: $block
      images: $images
      documents: $documents
      publishDateStr: $publishDateStr
      sendPush: $sendPush
      pushTitle: $pushTitle
      pushMessage: $pushMessage
      linkedProject: $linkedProject
    ) {
      ...SimpleMessage
    }
  }
  ${SimpleMessage}
`;

export const SaveNewsMutationConfig = {
  props: ({
    mutate,
    ownProps: {
      match: {
        params: {id: _id},
      },
    },
  }) => ({
    [SAVE_MESSAGE_PROP_NAME]: news =>
      mutate({
        variables: {_id, ...news},
        updateQueries: {
          allNews: (
            {allNews, ...prev},
            {
              mutationResult: {
                data: {saveNews},
              },
            }
          ) =>
            _id
              ? {...prev, allNews: allNews.map(news => (news._id === _id ? saveNews : news))}
              : {...prev, allNews: [saveNews, ...allNews]},
        },
      }),
  }),
};

export const SaveProjectMutation = gql`
  mutation saveProject(
    $_id: String
    $title: String!
    $text: String!
    $textRich: String!
    $youTubeId: String
    $region: String
    $toAll: Boolean
    $block: String
    $projectBlock: String
    $status: String
    $statusEntries: [StatusEntryInput]
    $images: [String!]
    $documents: [String!]
    $publishDateStr: String
    $sendPush: Boolean
    $pushTitle: String
    $pushMessage: String
  ) {
    saveProject(
      _id: $_id
      title: $title
      text: $text
      textRich: $textRich
      youTubeId: $youTubeId
      region: $region
      toAll: $toAll
      block: $block
      projectBlock: $projectBlock
      status: $status
      statusEntries: $statusEntries
      images: $images
      documents: $documents
      publishDateStr: $publishDateStr
      sendPush: $sendPush
      pushTitle: $pushTitle
      pushMessage: $pushMessage
    ) {
      ...SimpleMessage
    }
  }
  ${SimpleMessage}
`;

export const SaveProjectMutationConfig = {
  props: ({
    mutate,
    ownProps: {
      match: {
        params: {id: _id},
      },
    },
  }) => ({
    [SAVE_MESSAGE_PROP_NAME]: project =>
      mutate({
        variables: {_id, ...project},
        updateQueries: {
          allProjects: (
            {allProjects, ...prev},
            {
              mutationResult: {
                data: {saveProject},
              },
            }
          ) =>
            _id
              ? {...prev, allProjects: allProjects.map(project => (project._id === _id ? saveProject : project))}
              : {...prev, allProjects: [saveProject, ...allProjects]},
        },
      }),
  }),
};

export const SaveJobMutation = gql`
  mutation saveJob(
    $_id: String
    $title: String!
    $text: String!
    $textRich: String!
    $block: String
    $receiver: String
    $images: [String!]
    $documents: [String!]
    $endDateStr: String!
    $publishDateStr: String
    $commentsArePrivate: Boolean
    $sendPush: Boolean
    $pushTitle: String
    $pushMessage: String
  ) {
    saveJob(
      _id: $_id
      title: $title
      text: $text
      textRich: $textRich
      block: $block
      receiver: $receiver
      images: $images
      documents: $documents
      endDateStr: $endDateStr
      publishDateStr: $publishDateStr
      commentsArePrivate: $commentsArePrivate
      sendPush: $sendPush
      pushTitle: $pushTitle
      pushMessage: $pushMessage
    ) {
      ...SimpleMessage
    }
  }
  ${SimpleMessage}
`;

export const SaveJobMutationConfig = {
  props: ({
    mutate,
    ownProps: {
      match: {
        params: {id: _id},
      },
    },
  }) => ({
    [SAVE_MESSAGE_PROP_NAME]: job =>
      mutate({
        variables: {_id, ...job},
        updateQueries: {
          allJobs: (
            {allJobs, ...prev},
            {
              mutationResult: {
                data: {saveJob},
              },
            }
          ) =>
            _id && allJobs.findIndex(job => job._id === _id) !== -1
              ? {...prev, allJobs: allJobs.map(job => (job._id === _id ? saveJob : job))}
              : {...prev, allJobs: [saveJob, ...allJobs]},
          allMessages: (
            {allMessages, ...prev},
            {
              mutationResult: {
                data: {saveJob},
              },
            }
          ) => ({
            ...prev,
            allMessages: allMessages.filter(message => message._id !== _id),
          }),
        },
      }),
  }),
};

//Todo: request simple Poll as result
export const SavePollMutation = gql`
  mutation savePoll(
    $_id: String
    $title: String!
    $text: String!
    $textRich: String!
    $question: String!
    $region: String
    $blocks: [String]
    $toAll: Boolean
    $images: [String!]
    $documents: [String!]
    $options: [OptionInput!]
    $endDateStr: String!
    $publishDateStr: String
    $commentsArePrivate: Boolean
    $sendPush: Boolean
    $pushTitle: String
    $pushMessage: String
  ) {
    savePoll(
      _id: $_id
      title: $title
      text: $text
      textRich: $textRich
      question: $question
      region: $region
      blocks: $blocks
      toAll: $toAll
      images: $images
      documents: $documents
      options: $options
      endDateStr: $endDateStr
      publishDateStr: $publishDateStr
      commentsArePrivate: $commentsArePrivate
      sendPush: $sendPush
      pushTitle: $pushTitle
      pushMessage: $pushMessage
    ) {
      ...SimpleMessage
    }
  }
  ${SimpleMessage}
`;

export const SavePollMutationConfig = {
  props: ({
    mutate,
    ownProps: {
      match: {
        params: {id: _id},
      },
    },
  }) => ({
    [SAVE_MESSAGE_PROP_NAME]: poll =>
      mutate({
        variables: {_id, ...poll},
        updateQueries: {
          allPolls: (
            {allPolls, ...prev},
            {
              mutationResult: {
                data: {savePoll},
              },
            }
          ) =>
            _id
              ? {...prev, allPolls: allPolls.map(poll => (poll._id === _id ? savePoll : poll))}
              : {...prev, allPolls: [savePoll, ...allPolls]},
        },
      }),
  }),
};

//Todo: request simple Voting as result
export const SaveVotingMutation = gql`
  mutation saveVoting(
    $_id: String
    $title: String!
    $text: String!
    $textRich: String!
    $question: String!
    $blocks: [String!]
    $images: [String!]
    $documents: [String!]
    $options: [OptionInput!]
    $endDateStr: String!
    $publishDateStr: String
    $commentsArePrivate: Boolean
    $sendPush: Boolean
    $pushTitle: String
    $pushMessage: String
  ) {
    saveVoting(
      _id: $_id
      title: $title
      text: $text
      textRich: $textRich
      question: $question
      blocks: $blocks
      images: $images
      documents: $documents
      options: $options
      endDateStr: $endDateStr
      publishDateStr: $publishDateStr
      commentsArePrivate: $commentsArePrivate
      sendPush: $sendPush
      pushTitle: $pushTitle
      pushMessage: $pushMessage
    ) {
      ...SimpleMessage
    }
  }
  ${SimpleMessage}
`;

export const SaveVotingMutationConfig = {
  props: ({
    mutate,
    ownProps: {
      match: {
        params: {id: _id},
      },
    },
  }) => ({
    [SAVE_MESSAGE_PROP_NAME]: voting =>
      mutate({
        variables: {_id, ...voting},
        updateQueries: {
          allVotings: (
            {allVotings, ...prev},
            {
              mutationResult: {
                data: {saveVoting},
              },
            }
          ) =>
            _id
              ? {...prev, allVotings: allVotings.map(voting => (voting._id === _id ? saveVoting : voting))}
              : {...prev, allVotings: [saveVoting, ...allVotings]},
        },
      }),
  }),
};

export const ArchiveMutation = gql`
  mutation archiveMessage($_id: String!, $dateStr: String!) {
    archiveMessage(_id: $_id, dateStr: $dateStr) {
      ...SimpleMessage
    }
  }
  ${SimpleMessage}
`;

export const ArchiveMultipleMutation = gql`
  mutation archiveMultipleMessages($ids: [String!], $dateStr: String!) {
    archiveMultipleMessages(ids: $ids, dateStr: $dateStr) {
      ...SimpleMessage
    }
  }
  ${SimpleMessage}
`;

export const ArchiveMultipleMutationConfig = {name: 'archiveMultiple'};

export const ArchiveMutationConfig = {
  props: ({
    mutate,
    ownProps: {
      match: {
        params: {id: _id},
      },
    },
  }) => ({
    archive: () => mutate({variables: {_id, dateStr: new Date().toISOString()}}),
  }),
};

export const AddCommentMutation = gql`
  mutation addComment($message: String!, $text: String!, $images: [String!]) {
    addComment(message: $message, text: $text, images: $images) {
      ...Comment
    }
  }
  ${Comment}
`;

export const UpdateCommentMutation = gql`
  mutation updateComment($_id: String!, $text: String!, $images: [String!]) {
    updateComment(_id: $_id, text: $text, images: $images) {
      ...Comment
    }
  }
  ${Comment}
`;

const CommentMessageQuery = gql`
  query message($_id: String!) {
    message(_id: $_id) {
      _id
      comments {
        ...Comment
      }
    }
  }
  ${Comment}
`;

export const AddCommentMutationConfig = {
  props: ({
    ownProps: {
      match: {
        params: {id: _id},
      },
    },
    mutate,
  }) => ({
    addComment: (text, images) =>
      mutate({
        variables: {message: _id, text, images: images.map(({_id}) => _id)},
        update(
          client,
          {
            data: {addComment},
          }
        ) {
          let query = CommentMessageQuery;
          let data = client.readQuery({query, variables: {_id}});
          data.message.comments.push(addComment);
          client.writeQuery({query, data});
        },
      }),
  }),
};

export const UpdateCommentMutationConfig = {
  props: ({
    ownProps: {
      match: {
        params: {id: _id},
      },
    },
    mutate,
  }) => ({
    updateComment: (commentId, text, images) =>
      mutate({
        variables: {_id: commentId, text, images: images.map(({_id}) => _id)},
        update(
          client,
          {
            data: {updateComment},
          }
        ) {
          let query = CommentMessageQuery;
          let data = client.readQuery({query, variables: {_id}});
          const newComments = [];
          data.message.comments.forEach(item => {
            if (item._id !== commentId) {
              newComments.push(item);
            } else {
              newComments.push(updateComment);
            }
          });
          data.message.comments = newComments;
          client.writeQuery({query, data});
        },
      }),
  }),
};

export const CreatePdfMutation = gql`
  mutation createPdf($_id: String!) {
    createPdf(_id: $_id)
  }
`;

export const CreatePdfMutationConfig = {
  props: ({
    ownProps: {
      match: {
        params: {id: _id},
      },
    },
    mutate,
  }) => ({
    createPdf: () => mutate({variables: {_id}}),
  }),
};
export const DeleteUserMutation = gql`
  mutation deleteUser($_id: String!) {
    deleteUser(_id: $_id)
  }
`;

export const DeleteUserMutationConfig = {
  props: ({
    ownProps: {
      match: {
        params: {id: _id},
      },
    },
    mutate,
  }) => ({
    delete: () => mutate({variables: {_id}}),
  }),
};

export const SaveDefectMutation = gql`
  mutation saveDefect(
    $_id: String
    $title: String!
    $text: String!
    $textRich: String!
    $blocks: [String]
    $block: String
    $toEditor: Boolean
    $images: [String!]
    $documents: [String!]
    $publishDateStr: String
    $sendPush: Boolean
    $pushTitle: String
    $pushMessage: String
  ) {
    saveDefect(
      _id: $_id
      title: $title
      text: $text
      textRich: $textRich
      blocks: $blocks
      block: $block
      toEditor: $toEditor
      images: $images
      documents: $documents
      publishDateStr: $publishDateStr
      sendPush: $sendPush
      pushTitle: $pushTitle
      pushMessage: $pushMessage
    ) {
      ...SimpleMessage
    }
  }
  ${SimpleMessage}
`;

export const SaveDefectMutationConfig = {
  props: ({
    mutate,
    ownProps: {
      match: {
        params: {id: _id},
      },
    },
  }) => ({
    [SAVE_MESSAGE_PROP_NAME]: defect =>
      mutate({
        variables: {_id, ...defect},
        updateQueries: {
          allDefects: (
            {allDefects, ...prev},
            {
              mutationResult: {
                data: {saveDefect},
              },
            }
          ) =>
            _id
              ? {...prev, allDefects: allDefects.map(defect => (defect._id === _id ? saveDefect : defect))}
              : {...prev, allDefects: [saveDefect, ...allDefects]},
        },
      }),
  }),
};

export const SaveEscosMutation = gql`
  mutation saveEscos(
    $_id: String
    $title: String!
    $text: String!
    $textRich: String!
    $blocks: [String]
    $block: String
    $toEditor: Boolean
    $images: [String!]
    $documents: [String!]
    $publishDateStr: String
    $sendPush: Boolean
    $pushTitle: String
    $pushMessage: String
  ) {
    saveEscos(
      _id: $_id
      title: $title
      text: $text
      textRich: $textRich
      blocks: $blocks
      block: $block
      toEditor: $toEditor
      images: $images
      documents: $documents
      publishDateStr: $publishDateStr
      sendPush: $sendPush
      pushTitle: $pushTitle
      pushMessage: $pushMessage
    ) {
      ...SimpleMessage
    }
  }
  ${SimpleMessage}
`;

export const SaveEscosMutationConfig = {
  props: ({
    mutate,
    ownProps: {
      match: {
        params: {id: _id},
      },
    },
  }) => ({
    [SAVE_MESSAGE_PROP_NAME]: escos =>
      mutate({
        variables: {_id, ...escos},
        updateQueries: {
          allEscos: (
            {allEscos, ...prev},
            {
              mutationResult: {
                data: {saveEscos},
              },
            }
          ) =>
            _id
              ? {...prev, allEscos: allEscos.map(escos => (escos._id === _id ? saveEscos : escos))}
              : {...prev, allEscos: [saveEscos, ...allEscos]},
        },
      }),
  }),
};
