import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import {STATUS_PLANING, STATUS_BUILDING, STATUS_FINISHED} from '../constants/messageTypes';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const STATUSES = [
  {key: STATUS_PLANING, title: 'in Planung'},
  {key: STATUS_BUILDING, title: 'in Bau'},
  {key: STATUS_FINISHED, title: 'Bezugsfertig'}
];

export default ({onChange, status}) => (
  <FormGroup row>
    {STATUSES.map(({key, title}) => (
      <FormControlLabel
        control={
          <Checkbox
            checked={key === status}
            onChange={() => onChange(key)}
          />
        }
        key={key}
        label={title}
      />
    ))}
  </FormGroup>
);
