import React from 'react';
import MessageForm from '../components/MessageForm';
import {graphql, compose} from 'react-apollo';
import gql from 'graphql-tag';
import generalComposed from '../qql-shared/generalComposed';
import {SaveVotingMutation, SaveVotingMutationConfig} from '../qql-shared/mutations';
import {SimpleMessage, Attachments, SimpleOption, Comment} from '../qql-shared/fragments';
import {MessageQueryConfig} from '../qql-shared/queries';
import {MESSAGE_VOTING} from '../constants/messageTypes';

const VotingQuery = gql`
  query voting($_id:String!) {
    voting(_id:$_id) {
      ...SimpleMessage
      ...Attachments
      endDateStr
      userCount
      images(width: 240, height: 180) {...SimpleMedia}
      options {
        ...SimpleOption
        answers{
          user{
            _id
            firstName
            lastName
          }
        }
      }
      question
      comments{ ...Comment}
    }
  }
  ${SimpleMessage}
  ${Attachments}
  ${SimpleOption}
  ${Comment}
`;

export default compose(
  generalComposed,
  graphql(SaveVotingMutation, SaveVotingMutationConfig),
  graphql(VotingQuery, MessageQueryConfig)
)(props => <MessageForm {...props} type={MESSAGE_VOTING} />);
