import Documents from '../components/Documents';
import {graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {SimpleMessage} from '../qql-shared/fragments';

const DocumentsQuery = gql`
  query allMessageDocuments ($searchStr: String, $searchStrReceiver: String, $toEditors: Boolean) {
    allMessageDocuments(searchStr: $searchStr, searchStrReceiver: $searchStrReceiver, toEditors: $toEditors) {
      ...SimpleMessage
    }
  }
  ${SimpleMessage}
`;

export default graphql(DocumentsQuery, {
  options: {
    variables: {searchStr: '', searchStrReceiver: '', toEditors: ''},
    fetchPolicy: 'network-only',
  },
})(Documents);
