import {DetailMissingInputError} from '../errors/InputErrors';

const MISSING_FIELD = /Variable "\$(.*)" of required type .* was not provided\./;
const MISSING_FIELD_MONGOOSE = /.*validation failed: .*: Path `(.*)` is required\./;

function extractMissingFields(message) {
  let match = MISSING_FIELD.exec(message);
  if (!match) {
    match = MISSING_FIELD_MONGOOSE.exec(message);
  }
  return match && [match[1]];
}

export default ({graphQLErrors, networkError}) => {
  let errorMessages = [];
  if (graphQLErrors) {
    errorMessages = graphQLErrors.reduce((result, {message, ...rest}, i, {length}) => {
      let missingFields = rest.name === 'InputMissingError' ? rest.data.missingFields : extractMissingFields(message);
      if (missingFields) {
        result.push(DetailMissingInputError(missingFields.map(convertMissingField)));
      } else {
        result.push({message});
      }
      return result;
    }, errorMessages);
  }

  if (networkError) {
    errorMessages.push({message: 'Leider ist ein Problem mit der Verbindung aufgetreten.'});
  }

  return errorMessages;
};

function convertMissingField(field) {
  switch (field) {
    case 'email':
      return 'Email';
    case 'phone':
      return 'Telefonnummer';
    case 'firstName':
      return 'Vorname';
    case 'lastName':
      return 'Nachname';
    case 'title':
      return 'Titel';
    case 'text':
      return 'Text';
    case 'name':
      return 'Name';
    case 'region':
      return 'Region';
    case 'street':
      return 'Straße';
    case 'number':
      return 'Hausnummer';
    case 'zipCode':
      return 'PLZ';
    case 'city':
      return 'Ort';
    case 'password':
      return 'Passwort';
    case 'pushTitle':
      return 'Push Titel';
    case 'pushMessage':
      return 'Push Message';
    default:
      return field;
  }
}
