import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

export default ({onCancel, onConfirm, ...props}) => (
  <Dialog fullWidth maxWidth='xl' aria-labelledby='delete-dialog-title' {...props}>
    <DialogTitle id='delete-dialog-title'>Archivieren</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {props.multiple
          ? 'Die Einträge werden archiviert. Sind sie sich da wirklich sicher?'
          : 'Der Eintrag wird archiviert. Sind sie sich da wirklich sicher?'}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>Abbrechen</Button>
      <Button color='primary' onClick={onConfirm}>
        Ja
      </Button>
    </DialogActions>
  </Dialog>
);
