import 'babel-polyfill';
import React, {Fragment} from 'react';
import {ApolloProvider} from 'react-apollo';
import Navigation from './Navigation';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme.js';
import moment from 'moment';
import client from './client';
import GlobalStyles from "./components/GlobalStyles";
import 'moment/locale/de';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
moment.locale('de');

export default () =>
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ApolloProvider client={client}>
        <Fragment>
          <GlobalStyles />
          <Navigation />
        </Fragment>
      </ApolloProvider>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>;
