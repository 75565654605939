import Messages from '../components/Messages';
import {graphql, compose} from 'react-apollo';
import gql from 'graphql-tag';
import {SimpleMessage} from '../qql-shared/fragments';
import generalComposed from '../qql-shared/generalComposed';

const MessageQuery = gql`
  query allMessagesPaginated($input: AllMessagesPaginatedInput!) {
    allMessagesPaginated(input: $input) {
      result {
        ...SimpleMessage
      }
      totalItems
    }
  }
  ${SimpleMessage}
`;

export default compose(
  generalComposed,
  graphql(MessageQuery, {
    options: {
      variables: {input: {page: 1, resultsPerPage: 25, searchStr: '', hidden: false, searchStrReceiver: '', toEditors: false}},
      fetchPolicy: 'network-only',
    },
  })
)(Messages);
