import React from 'react';
import styled from 'styled-components';
import constants from '../parameters/constants';

const Option = styled.div`
  display: inline-block;
  border-radius: 5px;
  padding: 0px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  line-height: 30px;
  background-color: ${({selected}) => (selected ? constants.primaryColor : constants.complementaryColor)};
  color: ${({selected}) => (selected ? 'white' : 'black')};
  cursor: pointer;
`;

const _isSelected = (item, selected) => selected.findIndex(({_id}) => _id === item._id) !== -1;

const _toggle = (item, selected, updateMethod, multiSelect) => {
  if (!multiSelect) {
    updateMethod([item]);
    return;
  }
  let filtered = selected.filter(({_id}) => _id !== item._id);
  if (filtered.length === selected.length) filtered.push(item);
  updateMethod(filtered);
};

export default ({selectedItems, items, changeMethod, multiselect, renderItem}) =>
  <div>
    {items.map(item =>
      <Option
        key={item._id}
        selected={_isSelected(item, selectedItems)}
        onClick={() => _toggle(item, selectedItems, changeMethod, multiselect)}>
        {renderItem(item)}
      </Option>
    )}
  </div>;
