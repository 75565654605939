console.log(process.env);
const getConfig = () => ({
  apiUrl: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '',
  getAuthToken() {
    return localStorage.getItem('@RivaStore:authToken');
  },
  getPlainAuthToken() {
    let authToken = this.getAuthToken();
    return authToken ? authToken.replace('JWT ', '') : null;
  },
  getAuthHeader() {
    const authToken = this.getAuthToken();
    return authToken ? `Bearer ${authToken}` : null;
  },
});
export default getConfig();
