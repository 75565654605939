import React from 'react';
import EntityTableSearch from './table/EntityTableSearch';
import {Link} from 'react-router-dom';
import {NewButton} from './form/FormElements';
import {HeadlineH1} from './text/Headlines';
import filterArchivedMessages from '../helpers/filterArchivedMessages';
import getReceiverStringFromMessage from '../helpers/getReceiverStringFromMessage';
import moment from 'moment';
import styled from 'styled-components';

const Preview = styled.div`
  & p {
    margin: 0;
    padding: 0;
  }
`;

const _tableConfig = () => {
  return {
    maxRows: 25,
    cols: [
      {
        header: 'Datum',
        valueKey: 'publishDateStr',
        method: publishDateStr => moment(publishDateStr).format('LL'),
        width: '10%',
        sortKeyMethod: publishDateStr => new Date(publishDateStr).getTime(),
      },
      {
        header: 'Titel',
        valueKey: '*',
        method: row => row.title,
        width: '30%',
      },
      {
        header: 'Text',
        valueKey: '*',
        method: ({textRich}) => {
          return <Preview dangerouslySetInnerHTML={{__html: textRich}} />;
        },
        width: '40%',
        filterString: ({text}) => text,
      },
      {
        header: 'Empfänger',
        valueKey: '*',
        method: row => {
          return getReceiverStringFromMessage(row);
        },
        width: '20%',
      },
    ],
  };
};
export default ({data: {refetch, allMessageDocuments, variables: {searchStr, searchStrReceiver, toEditors}}, history}) => (
  <div>
    <HeadlineH1>Dokumente</HeadlineH1>
    <NewButton>
      <Link to='/new/document'>Neues Dokument</Link>
    </NewButton>
    <EntityTableSearch
      tableConfig={_tableConfig()}
      data={filterArchivedMessages(allMessageDocuments)}
      msg={'Noch keine Dokumente erstellt'}
      secondSearch={true}
      onSecondSearch={searchStrReceiver => refetch({searchStr, searchStrReceiver, toEditors})}
      checkBox={toEditors => refetch({searchStr, searchStrReceiver: '', toEditors})}
      onSearch={searchStr => refetch({searchStr, searchStrReceiver, toEditors})}
      onRowClicked={row => history.push('/document/' + row._id)}
    />
  </div>
);
