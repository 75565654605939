import React from 'react';
import MessageForm from '../components/MessageForm';
import {graphql, compose} from 'react-apollo';
import gql from 'graphql-tag';
import generalComposed from '../qql-shared/generalComposed';
import {SaveJobMutation, SaveJobMutationConfig} from '../qql-shared/mutations';
import {SimpleMessage, Comment, Attachments} from '../qql-shared/fragments';
import {
  MessageQueryConfig,
  AllDocumentsQuery,
  AllDocumentsQueryConfig,
  JobReceiversQuery,
  ReceiversQueryConfig
} from '../qql-shared/queries';
import {MESSAGE_JOB} from '../constants/messageTypes';

const JobQuery = gql`
  query job($_id:String!) {
    news(_id:$_id) {
      ...SimpleMessage
      ...Attachments
      endDateStr
      comments{ ...Comment}
      messageType
      jobAcceptedDateStr
      jobAcceptedUser {
        _id
        firstName
        lastName
      }
      jobFinishedDateStr
    }
  }
  ${SimpleMessage}
  ${Attachments}
  ${Comment}
`;

export default compose(
  generalComposed,
  graphql(AllDocumentsQuery, AllDocumentsQueryConfig),
  graphql(SaveJobMutation, SaveJobMutationConfig),
  graphql(JobQuery, MessageQueryConfig),
  graphql(JobReceiversQuery, ReceiversQueryConfig)
)(props => <MessageForm {...props} type={MESSAGE_JOB} />);
