import ApolloClient from 'apollo-client';
import config from './config';
import {createNetworkInterface} from 'apollo-upload-client';

const networkInterface = createNetworkInterface({
  uri: config.apiUrl + '/graphql',
});

const logErrors = {
  applyAfterware({response}, next) {
    if (!response.ok) {
      response
        .clone()
        .text()
        .then(bodyText => {
          if (bodyText.includes('TokenInvalidError') || bodyText.includes('TokenExpiredError')) {
            localStorage.removeItem('@RivaStore:authToken');
            window.location.reload();
          } else {
            console.log(bodyText);
            next();
          }
        });
    } else {
      next();
    }
  },
};
networkInterface.use([
  {
    applyMiddleware(req, next) {
      if (!req.options.headers) {
        req.options.headers = {}; // Create the header object if needed.
      }
      req.options.headers.authorization = config.getAuthHeader();
      next();
    },
  },
]);

networkInterface.useAfter([logErrors]);

export default new ApolloClient({networkInterface});
