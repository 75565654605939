import React from 'react';
import MessageForm from '../components/MessageForm';
import {graphql, compose} from 'react-apollo';
import gql from 'graphql-tag';
import generalComposed from '../qql-shared/generalComposed';
import {SimpleMessage, Attachments, Comment, SimpleBlock} from '../qql-shared/fragments';
import {AllProjectsQuery, MessageQueryConfig, ReceiversQueryConfig} from '../qql-shared/queries';
import {MESSAGE_DEFECT} from '../constants/messageTypes';
import {SaveDefectMutation, SaveDefectMutationConfig} from '../qql-shared/mutations';

const MessageDocumentQuery = gql`
  query defect($_id: String!) {
    defect(_id: $_id) {
      ...SimpleMessage
      ...Attachments
      comments {
        ...Comment
      }
      linkedProject {
        _id
        title
      }
    }
  }
  ${SimpleMessage}
  ${Attachments}
  ${Comment}
`;

const ReceiversQuery = gql`
  query receivers {
    allBlocks(hidden: false) {
      ...SimpleBlock
    }
  }
  ${SimpleBlock}
`;

export default compose(
  generalComposed,
  graphql(AllProjectsQuery),
  graphql(SaveDefectMutation, SaveDefectMutationConfig),
  graphql(MessageDocumentQuery, MessageQueryConfig),
  graphql(ReceiversQuery, ReceiversQueryConfig)
)(props => <MessageForm {...props} type={MESSAGE_DEFECT} />);
