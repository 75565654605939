import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import {OPTION_STRING, OPTION_IMAGE, OPTION_YOUTUBE} from '../constants/messageTypes';
import YouTubePreview, {youTubeIdIsValid} from './YouTubePreview';
import MediaPicker from './media-picker/MediaPicker';
import MediaUploader from './media-picker/MediaUploader';
import Checkbox from '@material-ui/core/Checkbox';
import {HeadlineH2} from './text/Headlines';
import RemoveIcon from '@material-ui/icons/Delete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';

const DEFAULT_STRING_OPTION = {type: OPTION_STRING, value: ''};
const DEFAULT_IMAGE_OPTION = {type: OPTION_IMAGE, value: []};
const DEFAULT_YOUTUBE_OPTION = {type: OPTION_YOUTUBE, value: ''};

const OPTIONS = [
  {type: OPTION_STRING, name: 'Text', option: DEFAULT_STRING_OPTION},
  {type: OPTION_IMAGE, name: 'Bild', option: DEFAULT_IMAGE_OPTION},
  {type: OPTION_YOUTUBE, name: 'YouTube', option: DEFAULT_YOUTUBE_OPTION},
];

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OptionWrapper = styled(Row)`
  ${({divider}) => divider && 'border-bottom: 1px solid lightgray;'} padding: 10px 0;
`;

const StyledRemoveIcon = styled(RemoveIcon)`
  font-size: 26px;
  min-width: 26px;
  cursor: pointer;
`;

const RemoveIconPlaceholder = styled.div`
  min-width: 26px;
`;

const StyledFormGroup = styled(FormGroup)`
  margin-right: 20px;
  padding: 10px 15px;
`;

const SmallPart = styled.div`
  width: 300px;
  padding-right: 30px;
`;

const AddButton = styled(Button)`
  margin-top: 10px;
`;

const Answers = styled.div`
  min-width: 150px;
  flex: 1;
  text-align: right;
  font-weight: bold;
`;

const AnswerUser = styled.span`
  display: inline-block;
  padding: 5px 10px;
`;

const TypePicker = ({type: selectedType, onChange}) => (
  <StyledFormGroup>
    {OPTIONS.map(({type, name, option}, i) => (
      <FormControlLabel
        key={i}
        control={<Checkbox style={{padding: 2}} checked={type === selectedType} onChange={() => onChange(option)} />}
        label={name}
      />
    ))}
  </StyledFormGroup>
);

const StringOption = ({value, onChange}) => (
  <TextField
    label='Text Auswahl'
    InputLabelProps={{
      shrink: true,
    }}
    type='text'
    fullWidth
    value={value || ''}
    onChange={({target: {value}}) => onChange(value)}
  />
);

const YouTubeOption = ({value, onChange}) => (
  <Row>
    <SmallPart>
      <TextField
        label='YouTube ID'
        InputLabelProps={{
          shrink: true,
        }}
        type='text'
        fullWidth
        value={value || ''}
        onChange={({target: {value}}) => onChange(value)}
      />
    </SmallPart>
    <YouTubePreview youTubeId={value} />
  </Row>
);

export default class extends Component {
  ImageOption = ({value, onChange}) => {
    let {allImagesPaginated, handleImageUpload, renderImage, renderSelectedImages} = this.props;
    return (
      <Row>
        <SmallPart>
          <MediaPicker
            title='Bild auswählen'
            multiple={false}
            items={allImagesPaginated}
            selectedItems={value}
            onSelected={images => onChange(images)}
            renderItem={renderImage}>
            <MediaUploader onUploadDone={handleImageUpload} fileTypes={['jpg', 'png']} />
          </MediaPicker>
        </SmallPart>
        {renderSelectedImages(value, () => onChange(DEFAULT_IMAGE_OPTION.value))}
      </Row>
    );
  };
  _renderOption = (opt, i, length) => {
    const {type, value, answers} = opt;
    let Option = StringOption;
    if (type === OPTION_IMAGE) Option = this.ImageOption;
    if (type === OPTION_YOUTUBE) Option = YouTubeOption;
    return (
      <div key={i}>
        <OptionWrapper divider={i !== length - 1}>
          {!answers ? <StyledRemoveIcon onClick={() => this.props.onRemoveOption(i)} /> : <RemoveIconPlaceholder />}
          <TypePicker type={type} onChange={option => this.props.onChangeOption(i, option)} />
          <Option value={value} onChange={value => this.props.onChangeOption(i, {...opt, value})} />
        </OptionWrapper>
        {answers && (
          <div>
            {answers.length > 0 && (
              <div>
                {answers.map(answer => {
                  return (
                    <AnswerUser key={answer.user._id}>
                      {answer.user.firstName} {answer.user.lastName}
                    </AnswerUser>
                  );
                })}
              </div>
            )}
            <Answers>Gewählt: {answers.length}</Answers>
          </div>
        )}
      </div>
    );
  };
  render() {
    let {options, addOption} = this.props;
    return (
      <div>
        <HeadlineH2>Auswahl Optionen</HeadlineH2>
        {options.map((option, i, {length}) => this._renderOption(option, i, length))}
        <AddButton variant='contained' onClick={() => addOption(DEFAULT_STRING_OPTION)}>
          Auswahl hinzufügen
        </AddButton>
      </div>
    );
  }
}
export function convertOptionBeforeSending({type, value, _id}) {
  switch (type) {
    case OPTION_YOUTUBE:
      return {
        option: {
          _id,
          type,
          youTubeId: value,
        },
        isValid: youTubeIdIsValid(value),
      };
    default:
    case OPTION_STRING:
      return {
        option: {
          _id,
          type,
          text: value,
        },
        isValid: value.length > 0,
      };
    case OPTION_IMAGE:
      const isValid = value && value.length > 0;
      return {
        option: {
          _id,
          type,
          image: isValid ? value[0]._id : undefined,
        },
        isValid,
      };
  }
}
export function convertIncomingOption({text, image, youTubeId, ...option}) {
  switch (option.type) {
    case OPTION_YOUTUBE:
      return {
        ...option,
        value: youTubeId,
      };
    default:
    case OPTION_STRING:
      return {
        ...option,
        value: text,
      };
    case OPTION_IMAGE:
      return {
        ...option,
        value: [image],
      };
  }
}
