import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Item from './Item';
import styled from 'styled-components';
import {graphql} from 'react-apollo';
import gql from 'graphql-tag';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const ItemListWrapper = styled.div`
  height: 500px;
  padding-top: 20px;
  margin: 0 -15px;
  overflow-y: scroll;
`;

export const HideMediaInListMutation = gql`
  mutation hideMediaInList($_id: String!) {
    hideMediaInList(_id: $_id) {
      _id
    }
  }
`;

export const HideMediaInListMutationConfig = {
  name: 'hideMediaInList',
  options: {
    updateQueries: {
      allImagesPaginated: (
        {allImagesPaginated, ...prev},
        {
          mutationResult: {
            data: {hideMediaInList},
          },
        }
      ) => ({
        ...prev,
        allImagesPaginated: allImagesPaginated.filter(({_id}) => _id !== hideMediaInList._id),
      }),
      allDocuments: (
        {allDocuments, ...prev},
        {
          mutationResult: {
            data: {hideMediaInList},
          },
        }
      ) => ({
        ...prev,
        allDocuments: allDocuments.filter(({_id}) => _id !== hideMediaInList._id),
      }),
    },
  },
};

class MediaPicker extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    multiple: PropTypes.bool.isRequired,
    selectedItems: PropTypes.array,
    onSelected: PropTypes.func.isRequired,
    renderItem: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selectedItems: props.selectedItems || [],
    };
  }

  componentWillReceiveProps = ({selectedItems}) => this.setState({selectedItems});

  _open = () => this.setState({open: true});

  _save = () => {
    this.props.onSelected(this.state.selectedItems);
    this.setState({open: false});
  };

  _selectItem = item => {
    if (this.props.multiple === true) {
      let selectedItems;
      if (this._isSelected(item)) {
        selectedItems = this.state.selectedItems.filter(({_id}) => _id !== item._id);
      } else {
        selectedItems = [item, ...this.state.selectedItems];
      }
      this.setState({selectedItems});
    } else {
      this.setState({selectedItems: [item]});
    }
  };

  _isSelected = ({_id}) => this.state.selectedItems.findIndex(i => i._id === _id) !== -1;

  renderItemList = () => (
    <ItemListWrapper>
      {this.props.items.map((item, idx) => (
        <Item
          key={idx}
          isSelected={this._isSelected(item)}
          onDelete={() => this.props.hideMediaInList({variables: {_id: item._id}})}
          onClick={() => this._selectItem(item)}>
          {this.props.renderItem(item)}
        </Item>
      ))}
    </ItemListWrapper>
  );

  render = () => (
    <div style={this.props.style} className={this.props.className}>
      <Button variant='contained' onClick={this._open}>
        {this.props.title}
      </Button>
      <Dialog open={this.state.open} fullWidth maxWidth='xl' aria-labelledby='media-picker-dialog-title'>
        <DialogTitle id='media-picker-dialog-title'>{this.props.title}</DialogTitle>
        <DialogContent style={{overflowY: 'hidden'}}>
          {this.props.children}
          {this.renderItemList()}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({open: false})}>Abbrechen</Button>
          <Button color='primary' onClick={this._save}>
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default graphql(HideMediaInListMutation, HideMediaInListMutationConfig)(MediaPicker);
