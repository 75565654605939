import React, {Component} from 'react';
import {HashRouter, Route, Redirect, Switch} from 'react-router-dom';
import LoginPage from './containers/LoginPage';
import ChangePasswordPage from './containers/ChangePasswordPage';
import ActivatedPage from './containers/ActivatedPage';
import MessagesPage from './containers/MessagesPage';
import MessageEdit from './containers/MessageEdit';
import MessageNew from './containers/MessageNew';
import PollsPage from './containers/PollsPage';
import PollEdit from './containers/PollEdit';
import PollNew from './containers/PollNew';
import VotingsPage from './containers/VotingsPage';
import VotingEdit from './containers/VotingEdit';
import VotingNew from './containers/VotingNew';
import NewsPage from './containers/NewsPage';
import NewsEdit from './containers/NewsEdit';
import NewsNew from './containers/NewsNew';
import ProjectsPage from './containers/ProjectsPage';
import ProjectEdit from './containers/ProjectEdit';
import ProjectNew from './containers/ProjectNew';
import UsersPage from './containers/UsersPage';
import UserNew from './containers/UserNew';
import UserEdit from './containers/UserEdit';
import BlocksPage from './containers/BlocksPage';
import BlockNew from './containers/BlockNew';
import BlockEdit from './containers/BlockEdit';
import RegionsPage from './containers/RegionsPage';
import RegionNew from './containers/RegionNew';
import RegionEdit from './containers/RegionEdit';
import {Centered1000} from './components/container/CenteredContainer';
import Sidebar from 'react-sidebar';
import styled from 'styled-components';
import {sidebarContent, sidebarStyles} from './components/SidebarContent';
import constants from './parameters/constants';
import JobNew from './containers/JobNew';
import JobEdit from './containers/JobEdit';
import JobsPage from './containers/JobsPage';
import DocumentsPage from './containers/DocumentsPage';
import DocumentEdit from './containers/DocumentEdit';
import DocumentNew from './containers/DocumentNew';
import DefectsPage from './containers/DefectsPage';
import DefectEdit from './containers/DefectEdit';
import EscosPage from './containers/EscosPage';
import EscosEdit from './containers/EscosEdit';

const SidebarToggle = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
  background: ${constants.complementaryColor};
  font-size: 25px;
  line-height: 40px;
  text-align: center;
  color: white;
  cursor: pointer;
  z-index: 500;
`;

const AuthRoute = ({component: Component, ...rest}) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem('@RivaStore:authToken') ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: {from: props.location},
          }}
        />
      )
    }
  />
);

export default class Navigation extends Component {
  constructor() {
    super();
    this.state = {sidebarOpen: false};
  }
  onSetSidebarOpen = open => {
    this.setState({sidebarOpen: open});
  };

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path='/reset-password/:token' component={ChangePasswordPage} />
          <Route exact path='/login' component={LoginPage} />
          <Route exact path='/activated' component={ActivatedPage} />
          <Sidebar
            sidebar={
              <div
                onClick={event => {
                  this.setState({sidebarOpen: false});
                }}>
                {sidebarContent}
              </div>
            }
            open={this.state.sidebarOpen}
            onSetOpen={this.onSetSidebarOpen}
            styles={sidebarStyles}>
            <SidebarToggle onClick={() => this.setState({sidebarOpen: true})}>R</SidebarToggle>
            <Centered1000>
              <Switch>
                <AuthRoute exact path='/messages' component={MessagesPage} />
                <AuthRoute exact path='/new/message' component={MessageNew} />
                <AuthRoute path='/message/:id' component={MessageEdit} />
                <AuthRoute exact path='/news' component={NewsPage} />
                <AuthRoute exact path='/new/news' component={NewsNew} />
                <AuthRoute path='/news/:id' component={NewsEdit} />
                <AuthRoute exact path='/projects' component={ProjectsPage} />
                <AuthRoute exact path='/new/project' component={ProjectNew} />
                <AuthRoute path='/projects/:id' component={ProjectEdit} />
                <AuthRoute exact path='/jobs' component={JobsPage} />
                <AuthRoute exact path='/new/job' component={JobNew} />
                <AuthRoute path='/job/:id' component={JobEdit} />
                <AuthRoute exact path='/polls' component={PollsPage} />
                <AuthRoute exact path='/new/poll' component={PollNew} />
                <AuthRoute path='/poll/:id' component={PollEdit} />
                <AuthRoute exact path='/votings' component={VotingsPage} />
                <AuthRoute exact path='/new/voting' component={VotingNew} />
                <AuthRoute path='/voting/:id' component={VotingEdit} />
                <AuthRoute exact path='/users' component={UsersPage} />
                <AuthRoute exact path='/new/user' component={UserNew} />
                <AuthRoute path='/users/:id' component={UserEdit} />
                <AuthRoute exact path='/blocks' component={BlocksPage} />
                <AuthRoute exact path='/new/block' component={BlockNew} />
                <AuthRoute path='/blocks/:id' component={BlockEdit} />
                <AuthRoute exact path='/regions' component={RegionsPage} />
                <AuthRoute exact path='/new/region' component={RegionNew} />
                <AuthRoute path='/regions/:id' component={RegionEdit} />
                <AuthRoute exact path='/documents' component={DocumentsPage} />
                <AuthRoute exact path='/new/document' component={DocumentNew} />
                <AuthRoute path='/document/:id' component={DocumentEdit} />
                <AuthRoute exact path='/defects' component={DefectsPage} />
                <AuthRoute path='/defect/:id' component={DefectEdit} />
                <AuthRoute exact path='/escos' component={EscosPage} />
                <AuthRoute path='/escos/:id' component={EscosEdit} />
                <Redirect to='/messages' />
              </Switch>
            </Centered1000>
          </Sidebar>
        </Switch>
      </HashRouter>
    );
  }
}
