import React from 'react';
import EntityTablePagination from './table/EntityTablePagination';
import {Link} from 'react-router-dom';
import {AdditionalButton, NewButton} from './form/FormElements';
import {HeadlineH1} from './text/Headlines';
import filterArchivedMessages from '../helpers/filterArchivedMessages';
import getReceiverStringFromMessage from '../helpers/getReceiverStringFromMessage';
import moment from 'moment';
import styled from 'styled-components';

const Preview = styled.div`
  & p {
    margin: 0;
    padding: 0;
  }
`;

const _tableConfig = (maxRows) => {
  return {
    maxRows,
    cols: [
      {
        header: 'Datum',
        valueKey: 'publishDateStr',
        method: publishDateStr => moment(publishDateStr).format('LL'),
        width: '10%',
        sortKeyMethod: publishDateStr => new Date(publishDateStr).getTime(),
      },
      {
        header: 'Titel',
        valueKey: '*',
        method: row => row.title,
        width: '30%',
      },
      {
        header: 'Text',
        valueKey: '*',
        method: ({textRich}) => {
          return <Preview dangerouslySetInnerHTML={{__html: textRich}} />;
        },
        width: '40%',
        filterString: ({text}) => text,
      },
      {
        header: 'Empfänger',
        valueKey: '*',
        method: row => {
          return getReceiverStringFromMessage(row);
        },
        width: '20%',
      },
    ],
  };
};
export default ({
  data: {
    allNewsPaginated,
    refetch,
    loading,
    variables: {
      input: {hidden, page, resultsPerPage, searchStr, searchStrReceiver, toEditors
      }
    },
  } = {},
  history,
}) => (
  <div>
    <HeadlineH1>News ({hidden ? 'archiviert' : 'sichtbar'})</HeadlineH1>
    <NewButton>
      <Link to='/new/news'>Neue News</Link>
    </NewButton>
    <AdditionalButton
      onClick={() => refetch({input: {page: 1, resultsPerPage, searchStr: '', hidden: !hidden, searchStrReceiver:'', toEditors}})}
      style={{cursor: 'pointer', marginRight: '-30px', color: 'white'}}>
      <div>{!hidden ? 'archiviert' : 'sichtbar'}</div>
    </AdditionalButton>
    <EntityTablePagination
      tableConfig={_tableConfig(resultsPerPage)}
      totalRows={loading ? 0 : allNewsPaginated.totalItems}
      data={loading ? [] : filterArchivedMessages(allNewsPaginated.result)}
      onRowClicked={row => history.push('/news/' + row._id)}
      currentPage={loading ? 0 : page - 1}
      perPage={resultsPerPage}
      onPageChanged={page => refetch({input: {page: page + 1, resultsPerPage, searchStr, hidden, searchStrReceiver, toEditors}})}
      onPerPageChanged={resultsPerPage => refetch({input: {page: 1, resultsPerPage, searchStr, hidden, searchStrReceiver, toEditors}})}
      onSearch={searchStr => refetch({input: {page: 1, resultsPerPage, searchStr, hidden, searchStrReceiver, toEditors}})}
      secondSearch={true}
      onSecondSearch={searchStrReceiver => refetch({input: {page: 1, resultsPerPage, searchStr, searchStrReceiver, hidden, toEditors}})}
      checkBox={toEditors => refetch({input: {page: 1, resultsPerPage, searchStr, hidden, searchStrReceiver: '', toEditors}})}
      msg={'Noch keine News erstellt'}
    />
  </div>
);
