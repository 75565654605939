import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {FormRow} from './form/FormElements';
import {Centered300} from './container/CenteredContainer';
import styled from 'styled-components';
import ErrorMessage from '../components/ErrorMessage';
import convertErrors from '../helpers/errorHelper';
import filterOutNotDefinedValues from '../helpers/filterOutNotDefinedValues';
import {ROLE_ADMIN, ROLE_EDITOR} from '../constants/roleConstants';
const logo = require('../img/riva-logo.png');

const StyledFormRow = styled(FormRow)`
  text-align: right;
`;

class Login extends Component {
  state = {email: '', password: ''};

  render() {
    return (
      <Centered300>
        <ErrorMessage errors={this.state.errors} clearErrors={() => this.setState({errors: []})} />
        <img src={logo} alt="riva-home" width={64} />
        <FormRow>
          <TextField
            placeholder="Email"
            fullWidth
            value={this.state.email}
            onChange={event => {
              this.setState({email: event.target.value});
            }}
          />
        </FormRow>
        <FormRow>
          <TextField
            placeholder="Passwort"
            type="password"
            fullWidth
            value={this.state.password}
            onChange={event => {
              this.setState({password: event.target.value});
            }}
          />
        </FormRow>
        <StyledFormRow>
          <Button
            color="primary"
            onClick={() =>
              this.props
                .login(filterOutNotDefinedValues(this.state))
                .then(({data: {login: {token, role}}}) => {
                  if (role === ROLE_ADMIN || role === ROLE_EDITOR) {
                    localStorage.setItem('@RivaStore:authToken', token);
                    this.props.history.replace('/messages');
                  } else {
                    this.setState({errors: [{message: 'Diese Seite ist nur für die Administration.'}]});
                  }
                })
                .catch(rawErrors => {
                  let errors = convertErrors(rawErrors);
                  this.setState({errors});
                  console.log('there was an error sending the query', errors);
                })}
          >
            anmelden
          </Button>
        </StyledFormRow>
      </Centered300>
    );
  }
}

export default Login;
