import News from '../components/News';
import {graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {SimpleMessage} from '../qql-shared/fragments';

const NewsQuery = gql`
query allNewsPaginated($input: AllNewsPaginatedInput!) {
  allNewsPaginated(input: $input) {
    result {
      ...SimpleMessage
      endDateStr
    }
    totalItems
  }
}
  ${SimpleMessage}
`;

export default graphql(NewsQuery, {
  options: {
    variables: {input: {hidden: false, resultsPerPage: 25, page: 1, searchStr: '', searchStrReceiver: '', toEditors: false}},
    fetchPolicy: 'network-only',
  },
})(News);
