import React from 'react';
import EntityTablePagination from './table/EntityTablePagination';
import {Link} from 'react-router-dom';
import {AdditionalButton, NewButton} from './form/FormElements';
import {HeadlineH1} from './text/Headlines';
import getReceiverStringFromMessage from '../helpers/getReceiverStringFromMessage';
import moment from 'moment';
import LoadingIndicator from './LoadingIndicator';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteModal from './DeleteModal';
import styled from 'styled-components';

const Preview = styled.div`
  & p {
    margin: 0;
    padding: 0;
  }
`;

const _tableConfig = () => {
  return {
    maxRows: 25,
    cols: [
      {
        header: 'Datum',
        valueKey: 'publishDateStr',
        method: publishDateStr => moment(publishDateStr).format('LL'),
        width: '10%',
        sortKeyMethod: publishDateStr => new Date(publishDateStr).getTime(),
      },
      {
        header: 'Titel',
        valueKey: '*',
        method: row => row.title,
        width: '30%',
      },
      {
        header: 'Text',
        valueKey: '*',
        method: ({textRich}) => {
          return <Preview dangerouslySetInnerHTML={{__html: textRich}} />;
        },
        width: '40%',
        filterString: ({text}) => text,
      },
      {
        header: 'Empfänger',
        valueKey: '*',
        method: row => {
          return getReceiverStringFromMessage(row);
        },
        width: '20%',
      },
    ],
  };
};
const Messages = ({
  data: {
    loading,
    allMessagesPaginated,
    refetch,
    variables: {
      input: {page, resultsPerPage, searchStr, hidden, searchStrReceiver, toEditors},
    },
  } = {},
  history,
  archiveMultiple,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [deleteIds, setDeleteIds] = React.useState([]);
  return (
    <div>
      <HeadlineH1>Nachrichten ({hidden ? 'archiviert' : 'sichtbar'})</HeadlineH1>
      <NewButton>
        <Link to='/new/message'>Neue Nachricht</Link>
      </NewButton>
      <AdditionalButton
        onClick={() => refetch({input: {page: 1, resultsPerPage, searchStr:'', hidden: !hidden, searchStrReceiver:'', toEditors}})}
        style={{cursor: 'pointer', marginRight: '20px', color: 'white'}}>
        <div>{!hidden ? 'archiviert' : 'sichtbar'}</div>
      </AdditionalButton>
      <EntityTablePagination
        multipleActions={[
          {
            content: () => <DeleteIcon />,
            onClick: messages => {
              setDeleteIds(messages.map(message => message._id));
              setDeleteModalOpen(true);
            },
          },
        ]}
        tableConfig={_tableConfig()}
        totalRows={loading ? 0 : allMessagesPaginated.totalItems}
        data={loading ? [] : allMessagesPaginated.result}
        currentPage={loading ? 0 : page - 1}
        perPage={resultsPerPage}
        onPageChanged={page => refetch({input: {page: page + 1, resultsPerPage, searchStr, searchStrReceiver, hidden, toEditors}})}
        onPerPageChanged={resultsPerPage => refetch({input: {page: 1, resultsPerPage, searchStr, searchStrReceiver, hidden, toEditors}})}
        onSearch={searchStr => refetch({input: {page: 1, resultsPerPage, searchStr, hidden, searchStrReceiver, toEditors}})}
        secondSearch={true}
        onSecondSearch={searchStrReceiver => refetch({input: {page: 1, resultsPerPage, searchStr, searchStrReceiver, hidden, toEditors}})}
        checkBox={toEditors => refetch({input: {page: 1, resultsPerPage, searchStr, hidden, searchStrReceiver: '', toEditors}})}
        msg={'Noch keine Nachrichten erstellt'}
        onRowClicked={row => history.push('/message/' + row._id)}
      />
      {loading && <LoadingIndicator />}
      <DeleteModal
        multiple
        open={deleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={() => {
          setDeleteModalOpen(false);
          archiveMultiple({variables: {ids: deleteIds, dateStr: new Date().toISOString()}}).then(() => {
            setDeleteIds([]);
            refetch({input: {page, resultsPerPage, searchStr, hidden, searchStrReceiver, toEditors}});
          });
        }}
      />
    </div>
  );
};
export default Messages;
