import React from 'react';
import MessageForm from '../components/MessageForm';
import {graphql, compose} from 'react-apollo';
import gql from 'graphql-tag';
import generalComposed from '../qql-shared/generalComposed';
import {SimpleMessage, Attachments, Comment} from '../qql-shared/fragments';
import {SaveMessageMutation, SaveMessageMutationConfig} from '../qql-shared/mutations';
import {MessageQueryConfig, AllProjectsQuery} from '../qql-shared/queries';
import {MESSAGE_MESSAGE} from '../constants/messageTypes';

const MessageQuery = gql`
  query message($_id: String!) {
    message(_id: $_id) {
      ...SimpleMessage
      ...Attachments
      comments {
        ...Comment
      }
      linkedProject {
        _id
        title
      }
    }
  }
  ${SimpleMessage}
  ${Attachments}
  ${Comment}
`;

export default compose(
  generalComposed,
  graphql(AllProjectsQuery),
  graphql(SaveMessageMutation, SaveMessageMutationConfig),
  graphql(MessageQuery, MessageQueryConfig)
)(props => <MessageForm {...props} type={MESSAGE_MESSAGE} />);
