import React, {Component} from 'react';
import RemoveIcon from '@material-ui/icons/Delete';
import styled from 'styled-components';
import {DeleteItemIconWrapper} from './DeleteItemIconWrapper';

const Wrapper = styled.div`
  display: inline-block;
  margin: 15px;
  position: relative;
  border-width: 4px;
  border-style: solid;
  border-color: transparent;
  ${({isSelected}) =>
    isSelected &&
    `
  border-color: #4F3B91;
  `}
  user-select: none;
  cursor: pointer;
`;

export default class extends Component {
  onDeleteConfirm = event => {
    event.stopPropagation();
    this.props.onDelete();
  };

  render() {
    const {onDelete, ...props} = this.props;
    return (
      <Wrapper {...props}>
        <DeleteItemIconWrapper onClick={this.onDeleteConfirm}>
          <RemoveIcon />
        </DeleteItemIconWrapper>
        {this.props.children}
      </Wrapper>
    );
  }
}
