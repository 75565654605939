import Escos from '../components/Escos';
import {graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {SimpleMessage} from '../qql-shared/fragments';

const EscosQuery = gql`
  query allEscos($hidden: Boolean!, $searchStr: String, $searchStrReceiver: String, $toEditors: Boolean) {
    allEscos(hidden: $hidden, searchStr: $searchStr, searchStrReceiver: $searchStrReceiver, toEditors: $toEditors) {
      ...SimpleMessage
    }
  }
  ${SimpleMessage}
`;

export default graphql(EscosQuery, {
  options: {
    variables: {hidden: false, searchStr: '', searchStrReceiver: '', toEditors: ''},
    fetchPolicy: 'network-only',
  },
})(Escos);
