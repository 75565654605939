import React from 'react';
import constants from '../parameters/constants';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const NaviItem = styled.div`
  line-height: 50px;
  font-size: 25px;
  &:first-of-type {
    margin-top: 200px;
  }
  a,
  a:hover {
    color: ${constants.primaryColor};
    text-decoration: none;
  }
`;

const Logout = styled(NaviItem)`
  margin-top: 220px;
  color: ${constants.primaryColor};
`;

export const sidebarContent = (
  <div>
    <NaviItem>
      <Link to={'/messages'}>Nachrichten</Link>
    </NaviItem>
    <NaviItem>
      <Link to={'/news'}>News</Link>
    </NaviItem>
    <NaviItem>
      <Link to={'/projects'}>Projekte</Link>
    </NaviItem>
    <NaviItem>
      <Link to={'/jobs'}>Jobs</Link>
    </NaviItem>
    <NaviItem>
      <Link to={'/polls'}>Umfragen</Link>
    </NaviItem>
    <NaviItem>
      <Link to={'/votings'}>Abstimmungen</Link>
    </NaviItem>
    <NaviItem>
      <Link to={'/documents'}>Dokumente</Link>
    </NaviItem>
    <NaviItem>
      <Link to={'/defects'}>Störung/Mangel</Link>
    </NaviItem>
    <NaviItem>
      <Link to={'/escos'}>ESCOS Ideen</Link>
    </NaviItem>
    <NaviItem>
      <Link to={'/users'}>Benutzer</Link>
    </NaviItem>
    <NaviItem>
      <Link to={'/blocks'}>Wohnanlagen</Link>
    </NaviItem>
    <NaviItem>
      <Link to={'/regions'}>Regionen</Link>
    </NaviItem>
    <Logout
      onClick={() => {
        localStorage.removeItem('@RivaStore:authToken');
        window.location.reload();
      }}>
      Abmelden
    </Logout>
  </div>
);

export const sidebarStyles = {
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
  },
  sidebar: {
    zIndex: 2500,
    position: 'absolute',
    top: 0,
    bottom: 0,
    transition: 'transform .3s ease-out',
    WebkitTransition: '-webkit-transform .3s ease-out',
    willChange: 'transform',
    overflowY: 'auto',
    background: constants.complementaryColor,
    padding: 40,
    minWidth: 180,
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch',
    transition: 'left .3s ease-out, right .3s ease-out',
  },
  overlay: {
    zIndex: 2,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity .3s ease-out, visibility .3s ease-out',
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  dragHandle: {
    zIndex: 1,
    position: 'fixed',
    top: 0,
    bottom: 0,
  },
};
