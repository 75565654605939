import React from 'react';
import {HeadlineH1} from '../components/text/Headlines';
import {Centered500} from '../components/container/CenteredContainer';
import styled from 'styled-components';

const Wrapper = styled.div`margin-bottom: 30px;`;

const ErrorRegex = /\?error=(.*)/g;

const Success = () =>
  <Centered500>
    <HeadlineH1>Benutzer aktiviert</HeadlineH1>
    <Wrapper>
      Dein Benutzer wurde erfolgreich aktiviert.
      <br />
      Du kannst dich nun in der App anmelden.
    </Wrapper>
  </Centered500>;

const Error = ({error}) =>
  <Centered500>
    <HeadlineH1>Fehler bei der Aktivierung</HeadlineH1>
    <Wrapper>
      {error}
      <br />
      <br />
      Unter folgender Adresse sind wir für Fragen & Hilfe erreichbar:<br />
      <a href="mailto:info@rivahome.at">info@rivahome.at</a>
    </Wrapper>
  </Centered500>;

function getErrorText(error) {
  switch (error) {
    case 'user-or-token':
      return 'Der Benutzer ist bereits aktiviert oder der Aktivierungs-Token ist nicht mehr gültig.';
    case 'save':
      return 'Das Aktivieren hat leider nicht funktioniert. Bitte versuche es erneut.';
    default:
      return 'Beim Aktivieren ist leider ein Fehler aufgetreten.';
  }
}

export default ({location: {search}}) => {
  let errors = ErrorRegex.exec(search);
  if (errors) {
    let error = errors[1];
    let text = getErrorText(error);
    return <Error error={text} />;
  }
  return <Success />;
};
