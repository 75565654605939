import React from 'react';

export const youTubeIdIsValid = youTubeId => youTubeId && youTubeId.length === 11;

export default ({youTubeId}) =>
  youTubeIdIsValid(youTubeId) ? (
    <iframe
      title="preview"
      type="text/html"
      width="400"
      height="225"
      frameBorder="0"
      src={`https://www.youtube.com/embed/${youTubeId}?rel=0&autoplay=0&showinfo=0&controls=0`}
    />
  ) : null;
