import React from 'react';
import EntityTable from './table/EntityTable';
import {Link} from 'react-router-dom';
import {NewButton} from './form/FormElements';
import {HeadlineH1} from './text/Headlines';

const _tableConfig = () => {
  return {
    maxRows: 25,
    cols: [
      {
        header: 'Name',
        valueKey: 'name',
        method: name => name,
        width: '25%'
      },
      {
        header: 'Beschreibung',
        valueKey: 'description',
        method: description => description,
        width: '25%'
      }
    ]
  };
};

export default ({data, history}) => {
  return (
    <div>
      <HeadlineH1>Regionen</HeadlineH1>
      <NewButton>
        <Link to="/new/region">Neue Region</Link>
      </NewButton>
      <EntityTable
        tableConfig={_tableConfig()}
        data={data.allRegions || []}
        msg={'Noch keine Region erstellt'}
        onRowClicked={row => history.push('/regions/' + row._id)}
      />
    </div>
  );
};
