export default function(message) {
  if (!message) return '';
  if (message.toAll === true) {
    return 'An Alle';
  } else if (message.toEditor === true) {
    return 'An Riva Betreuer';
  } else if (message.receiver) {
    return 'Benutzer: ' + message.receiver.firstName + ' ' + message.receiver.lastName;
  } else if (message.blocks && message.blocks.length > 0) {
    return `${message.blocks.length === 1 ? 'Wohnanlage' : 'Wohnanlagen'}: ${message.blocks.map(
      block => ` ${block.name}`
    )}`;
  } else if (message.region) {
    return 'Region: ' + message.region.name;
  } else {
    return 'Riva Home';
  }
}
