import React from 'react';
import MessageForm from '../components/MessageForm';
import {graphql, compose} from 'react-apollo';
import gql from 'graphql-tag';
import generalComposed from '../qql-shared/generalComposed';
import {SavePollMutation, SavePollMutationConfig} from '../qql-shared/mutations';
import {SimpleMessage, Attachments, SimpleOption, Comment} from '../qql-shared/fragments';
import {MessageQueryConfig} from '../qql-shared/queries';
import {MESSAGE_POLL} from '../constants/messageTypes';

const PollQuery = gql`
  query poll($_id:String!) {
    poll(_id:$_id) {
      ...SimpleMessage
      ...Attachments
      endDateStr
      userCount
      options {
        ...SimpleOption
        answers{
          user{
            _id
            firstName
            lastName
          }
        }
      }
      question
      comments{ ...Comment}
      
    }
  }
  ${SimpleMessage}
  ${Attachments}
  ${SimpleOption}
  ${Comment}
`;

export default compose(
  generalComposed,
  graphql(SavePollMutation, SavePollMutationConfig),
  graphql(PollQuery, MessageQueryConfig)
)(props => <MessageForm {...props} type={MESSAGE_POLL} />);
