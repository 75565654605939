import React from 'react';
import MessageForm from '../components/MessageForm';
import {graphql, compose} from 'react-apollo';
import gql from 'graphql-tag';
import generalComposed from '../qql-shared/generalComposed';
import {SimpleBlock, SimpleRegion} from '../qql-shared/fragments';
import {SavePollMutation, SavePollMutationConfig} from '../qql-shared/mutations';
import {ReceiversQueryConfig} from '../qql-shared/queries';
import {MESSAGE_POLL} from '../constants/messageTypes';

const ReceiversQuery = gql`
  query receivers {
    allRegions {
      ...SimpleRegion
    }
    allBlocks(hidden: false) {
      ...SimpleBlock
    }
  }
  ${SimpleBlock}
  ${SimpleRegion}
`;

export default compose(
  generalComposed,
  graphql(SavePollMutation, SavePollMutationConfig),
  graphql(ReceiversQuery, ReceiversQueryConfig)
)(props => <MessageForm {...props} type={MESSAGE_POLL} />);
