export const MESSAGE_NEWS = 'news';
export const MESSAGE_MESSAGE = 'message';
export const MESSAGE_JOB = 'job';
export const MESSAGE_VOTING = 'voting';
export const MESSAGE_POLL = 'poll';
export const MESSAGE_PROJECT = 'project';
export const MESSAGE_DOCUMENT = 'document';
export const MESSAGE_DEFECT = 'defect';
export const MESSAGE_ESCOS = 'escos';

export const OPTION_STRING = 'text';
export const OPTION_IMAGE = 'image';
export const OPTION_YOUTUBE = 'video';

export const STATUS_PLANING = 'planing';
export const STATUS_BUILDING = 'building';
export const STATUS_FINISHED = 'finished';
