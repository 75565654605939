import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import {HeadlineH1, HeadlineH2} from './text/Headlines';
import Selector from './Selector';
import ActionButtonRow from '../components/ActionButtonRow';
import filterOutNotDefinedValues from '../helpers/filterOutNotDefinedValues';
import ErrorMessage from '../components/ErrorMessage';
import convertErrors from '../helpers/errorHelper';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteModal from './DeleteModal';

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      email: '',
      phone: '',
      firstName: '',
      lastName: '',
      password: '',
      role: 'Prospect',
      changePassword: false,
      blocks: [],
      deleteModalOpen: false,
      savingStatus: this._isNew(props) ? undefined : 'Daten werden geladen...',
      ...filterOutNotDefinedValues(props.data.user),
    };
  }

  componentWillReceiveProps = newProps => {
    console.log(newProps);
    if (newProps.data.error) {
      const {graphQLErrors} = newProps.data.error || {};
      this.setState({errors: graphQLErrors});
    } else {
      const {user} = newProps.data || {};
      this.setState(({savingStatus}) => ({
        errors: [],
        ...filterOutNotDefinedValues(user),
        savingStatus: user && user._id ? undefined : savingStatus,
      }));
    }
  };
  _goBackToList = () => this.props.history.replace('/users');

  _renderHeadline = () => {
    if (this._isNew()) {
      return <HeadlineH1>Neuer Benutzer</HeadlineH1>;
    }
    return <HeadlineH1>Benutzer bearbeiten</HeadlineH1>;
  };

  _isNew = (props = this.props) => !props.match.params.id;

  lastSave = undefined;
  _save = () => {
    const now = Date.now();
    if (this.lastSave && now - this.lastSave < 1000) return;
    this.lastSave = now;
    this.setState({savingStatus: 'Daten werden gespeichert...'});
    this.props
      .save({...filterOutNotDefinedValues(this.state), blocks: this.state.blocks.map(({_id}) => _id)})
      .then(() => {
        this.setState({savingStatus: undefined});
        this._goBackToList();
      })
      .catch(rawErrors => {
        let errors = convertErrors(rawErrors);
        this.setState({errors, savingStatus: undefined});
        console.log('there was an error sending the query', errors);
      });
  };

  render() {
    let {active, email, phone, firstName, lastName, password, role, changePassword, blocks, savingStatus} = this.state;
    let savingDisabled = !!savingStatus;
    return (
      <div>
        <ErrorMessage errors={this.state.errors} clearErrors={() => this.setState({errors: []})} />
        {this._renderHeadline()}
        <FormControlLabel
          control={<Switch checked={active} onChange={(_, active) => this.setState({active})} />}
          label={'Benutzer ' + (active ? 'aktiviert' : 'deaktiviert')}
        />
        <TextField
          type='text'
          fullWidth
          label='Vorname*'
          value={firstName}
          onChange={({target: {value}}) => this.setState({firstName: value})}
        />
        <TextField
          type='text'
          fullWidth
          label='Nachname*'
          value={lastName}
          onChange={({target: {value}}) => this.setState({lastName: value})}
        />
        <TextField
          type='text'
          fullWidth
          label='Email*'
          value={email}
          onChange={({target: {value}}) => this.setState({email: value})}
        />
        <TextField
          type='text'
          fullWidth
          label='Telefonnummer'
          value={phone}
          onChange={({target: {value}}) => this.setState({phone: value})}
        />
        <FormControl>
          <InputLabel htmlFor='role-select'>Rolle</InputLabel>
          <Select
            value={role}
            onChange={({target}) => this.setState({role: target.value})}
            inputProps={{
              name: 'role-select',
              id: 'role-select',
            }}>
            <MenuItem value='Prospect'>Intressent</MenuItem>
            <MenuItem value='Member'>Bewohner</MenuItem>
            <MenuItem value='Editor'>Redakteur</MenuItem>
            <MenuItem value='Admin'>Admin</MenuItem>
          </Select>
        </FormControl>
        <HeadlineH2>Wohnanlagen</HeadlineH2>
        <Selector
          items={this.props.data.allBlocks || []}
          multiselect
          selectedItems={blocks}
          changeMethod={blocks => this.setState({blocks})}
          renderItem={({name}) => name}
        />
        {(this._isNew() || changePassword) && (
          <TextField
            type='password'
            fullWidth
            label='Passwort*'
            value={password}
            onChange={({target: {value}}) => this.setState({password: value})}
          />
        )}

        <DeleteModal
          open={this.state.deleteModalOpen}
          onCancel={() =>
            this.setState({
              deleteModalOpen: false,
            })
          }
          onConfirm={() => this.props.delete().then(this._goBackToList)}
        />
        <ActionButtonRow>
          <Button onClick={this._goBackToList}>Zurück zur Übersicht</Button>
          <div>
            {!this._isNew() && <Button onClick={() => this.setState({deleteModalOpen: true})}>Benutzer löschen</Button>}
            {!this._isNew() && <Button onClick={() => this.setState({changePassword: true})}>Passwort ändern</Button>}
            <Button color='primary' onClick={this._save} disabled={savingDisabled}>
              {savingDisabled ? savingStatus : 'speichern'}
            </Button>
          </div>
        </ActionButtonRow>
      </div>
    );
  }
}
