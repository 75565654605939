import React from 'react';
import MessageForm from '../components/MessageForm';
import {graphql, compose} from 'react-apollo';
import gql from 'graphql-tag';
import generalComposed from '../qql-shared/generalComposed';
import {SaveNewsMutation, SaveNewsMutationConfig} from '../qql-shared/mutations';
import {SimpleMessage, Attachments} from '../qql-shared/fragments';
import {MessageQueryConfig, AllDocumentsQuery, AllDocumentsQueryConfig, AllProjectsQuery} from '../qql-shared/queries';
import {MESSAGE_NEWS} from '../constants/messageTypes';

const NewsQuery = gql`
  query news($_id: String!) {
    news(_id: $_id) {
      ...SimpleMessage
      ...Attachments
      youTubeId
    }
  }
  ${SimpleMessage}
  ${Attachments}
`;

export default compose(
  generalComposed,
  graphql(AllDocumentsQuery, AllDocumentsQueryConfig),
  graphql(AllProjectsQuery),
  graphql(SaveNewsMutation, SaveNewsMutationConfig),
  graphql(NewsQuery, MessageQueryConfig)
)(props => <MessageForm {...props} type={MESSAGE_NEWS} />);
