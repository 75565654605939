import React from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import styled from 'styled-components';

const List = styled.div`
  display: flex;
  overflow: auto;
  margin: 0 -10px;
`;

export function SortablePreview({id, data, renderItem, getId = item => item._id, onRemove, onChange}) {

  if (!data || !data.length) return null

  const onDragEnd = result => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      onRemove(draggableId);
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newArray = Array.from(data);
    const [item] = newArray.splice(source.index, 1);
    newArray.splice(destination.index, 0, item);

    onChange(newArray)
  };

  const toDraggable = (item, idx) => (
    <Draggable draggableId={getId(item)} key={getId(item)} index={idx}>
      {provided => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {renderItem(item, idx)}
        </div>)}
    </Draggable>
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={id} direction="horizontal">
        {provided => (
          <List ref={provided.innerRef} {...provided.droppableProps}>
            {data.map(toDraggable)}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
}
