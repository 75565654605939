import Users from '../components/Users';
import {graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {User} from '../qql-shared/fragments';

const UsersQuery = gql`
  query allUsers {
    allUsers {
      ...User
    }
  }
  ${User}
`;

export default graphql(UsersQuery, {options: {fetchPolicy: 'network-only'}})(Users);
