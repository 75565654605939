import React from 'react';
import MessageForm from '../components/MessageForm';
import {graphql, compose} from 'react-apollo';
import gql from 'graphql-tag';
import generalComposed from '../qql-shared/generalComposed';
import {SaveProjectMutation, SaveProjectMutationConfig} from '../qql-shared/mutations';
import {SimpleMessage, Attachments, SimpleBlock, SimpleMedia} from '../qql-shared/fragments';
import {MessageQueryConfig, AllDocumentsQuery, AllDocumentsQueryConfig} from '../qql-shared/queries';
import {MESSAGE_PROJECT} from '../constants/messageTypes';

const ProjectQuery = gql`
  query project($_id: String!) {
    project(_id: $_id) {
      ...SimpleMessage
      ...Attachments
      youTubeId
      projectBlock {
        ...SimpleBlock
      }
      status
      statusEntries {
        text
        done
        date
        images(width: 300) {
          ...SimpleMedia
        }
      }
    }
  }
  ${SimpleMessage}
  ${Attachments}
  ${SimpleBlock}
  ${SimpleMedia}
`;

export default compose(
  generalComposed,
  graphql(AllDocumentsQuery, AllDocumentsQueryConfig),
  graphql(SaveProjectMutation, SaveProjectMutationConfig),
  graphql(ProjectQuery, MessageQueryConfig)
)(props => <MessageForm {...props} type={MESSAGE_PROJECT} />);
