import ChangePassword from '../components/ChangePassword';
import {graphql} from 'react-apollo';
import gql from 'graphql-tag';

const ChangePasswordMutation = gql`
  mutation changePassword($token: String!, $password: String!) {
    changePassword(token: $token, password: $password)
  }
`;

export const ChangePasswordMutationConfig = {
  props: ({mutate, ownProps: {match: {params: {token}}}}) => ({
    changePassword: password => mutate({variables: {token, password}})
  })
};

export default graphql(ChangePasswordMutation, ChangePasswordMutationConfig)(ChangePassword);
