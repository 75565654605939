import React, {useState} from 'react';
import {Editor, convertToRaw, RichUtils, EditorState} from 'draft-js';
import 'draft-js/dist/Draft.css';
import styled from 'styled-components';
import Label from '@material-ui/core/InputLabel';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';
import ColorPicker, {colorPickerPlugin} from 'draft-js-color-picker';

const Wrapper = styled.div`
  margin: 30px 0;
`;

const EditorWrapper = styled.div`
  border-top: 2px solid lightgray;
  border-bottom: 2px solid lightgray;
  padding-bottom: 10px;
  margin-top: 10px;
`;

const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  margin-top: 8px;
`;

const Action = styled.div`
  padding: 0 7px;
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
  border-radius: 1px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
  font-size: 12px;
  height: 24px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
`;

const presetColors = [
  '#ff00aa',
  '#F5A623',
  '#F8E71C',
  '#8B572A',
  '#7ED321',
  '#417505',
  '#BD10E0',
  '#9013FE',
  '#4A90E2',
  '#50E3C2',
  '#B8E986',
  '#000000',
  '#4A4A4A',
  '#9B9B9B',
  '#FFFFFF',
];

const options = {
  customInlineFn: (element, {Style, Entity}) => {
    if (element.style.color) {
      return Style('CUSTOM_COLOR_' + element.style.color);
    }
  },
};

const RichEditor = ({value, onChange, label, placeholder}) => {
  const initValue = value ? EditorState.createWithContent(stateFromHTML(value, options)) : EditorState.createEmpty();
  const [state, setState] = useState(initValue);

  const picker = colorPickerPlugin(state => setStateFunc(state), () => state);

  const convertToText = state => {
    const blocks = convertToRaw(state.getCurrentContent()).blocks;
    const text = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
    return text;
  };

  const convertToHtml = state => {
    const inlineStyles = picker.exporter(state);
    const textRich = stateToHTML(state.getCurrentContent(), {inlineStyles});
    return textRich;
  };

  const setStateFunc = state => {
    setState(state);
    const text = convertToText(state);
    const textRich = convertToHtml(state);
    onChange({textRich, text});
  };

  const handleStyle = style => {
    setStateFunc(RichUtils.toggleInlineStyle(state, style));
  };

  return (
    <Wrapper>
      <Label>{label}</Label>
      <EditorWrapper>
        <ActionRow>
          <Action onClick={() => handleStyle('BOLD')}>Fett</Action>
          <Action onClick={() => handleStyle('ITALIC')}>Kursiv</Action>
          <ColorPicker
            toggleColor={color => picker.addColor(color)}
            presetColors={presetColors}
            color={picker.currentColor(state) || '#000'}
          />
        </ActionRow>
        <Editor
          customStyleFn={picker.customStyleFn}
          editorState={state}
          placeholder={placeholder}
          onChange={textRich => setStateFunc(textRich)}
        />
      </EditorWrapper>
    </Wrapper>
  );
};

export default RichEditor;
