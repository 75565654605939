import React from 'react';
import MessageForm from '../components/MessageForm';
import {graphql, compose} from 'react-apollo';
import gql from 'graphql-tag';
import generalComposed from '../qql-shared/generalComposed';
import {SaveMessageDocumentMutation, SaveMessageDocumentMutationConfig} from '../qql-shared/mutations';
import {ReceiversQueryConfig, AllProjectsQuery} from '../qql-shared/queries';
import {MESSAGE_DOCUMENT} from '../constants/messageTypes';

const ReceiversQuery = gql`
  query receivers {
    allUsers {
      _id
      email
      firstName
      lastName
    }
  }
`;

export default compose(
  generalComposed,
  graphql(SaveMessageDocumentMutation, SaveMessageDocumentMutationConfig),
  graphql(AllProjectsQuery),
  graphql(ReceiversQuery, ReceiversQueryConfig)
)(props => <MessageForm {...props} type={MESSAGE_DOCUMENT} />);
