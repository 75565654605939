import styled from 'styled-components';

export const DeleteItemIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  color: white;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);
`;
