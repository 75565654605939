import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {HeadlineH1} from './text/Headlines';
import ActionButtonRow from '../components/ActionButtonRow';
import filterOutNotDefinedValues from '../helpers/filterOutNotDefinedValues';
import ErrorMessage from '../components/ErrorMessage';
import convertErrors from '../helpers/errorHelper';

export default class extends Component {
  constructor(props) {
    super(props);
    let region = props.data ? props.data.region : {};
    this.state = {
      name: '',
      description: '',
      ...filterOutNotDefinedValues(region),
      savingStatus: this._isNew(props) ? undefined : 'Daten werden geladen...'
    };
  }

  componentWillReceiveProps = newProps => {
    if (newProps.data.error) {
      const {graphQLErrors} = newProps.data.error || {};
      this.setState({errors: graphQLErrors});
    } else {
      const {region} = newProps.data || {};
      this.setState(({savingStatus}) => ({
        errors: [],
        ...filterOutNotDefinedValues(region),
        savingStatus: region && region._id ? undefined : savingStatus
      }));
    }
  };
  _goBackToList = () => this.props.history.replace('/regions');

  _renderHeadline = () => {
    if (this._isNew()) {
      return <HeadlineH1>Neue Region</HeadlineH1>;
    }
    return <HeadlineH1>Region bearbeiten</HeadlineH1>;
  };

  _isNew = (props = this.props) => !props.match.params.id;

  lastSave = undefined;
  _save = () => {
    const now = Date.now();
    if (this.lastSave && now - this.lastSave < 1000) return;
    this.lastSave = now;
    this.setState({savingStatus: 'Daten werden gespeichert...'});
    this.props
      .save(filterOutNotDefinedValues(this.state))
      .then(() => {
        this.setState({savingStatus: undefined});
        this._goBackToList();
      })
      .catch(rawErrors => {
        let errors = convertErrors(rawErrors);
        this.setState({errors, savingStatus: undefined});
        console.log('there was an error sending the query', errors);
      });
  };

  render() {
    let {name, description, savingStatus} = this.state;
    let savingDisabled = !!savingStatus;
    return (
      <div>
        <ErrorMessage errors={this.state.errors} clearErrors={() => this.setState({errors: []})} />
        {this._renderHeadline()}
        <TextField
          type="text"
          fullWidth
          label="Name*"
          value={name}
          onChange={({target: {value}}) => this.setState({name: value})}
        />
        <TextField
          type="text"
          fullWidth
          label="Beschreibung"
          value={description}
          onChange={({target: {value}}) => this.setState({description: value})}
        />
        <ActionButtonRow>
          <Button onClick={this._goBackToList}>Zurück zur Übersicht</Button>
          <div>
            {/*{!this.isNew() && <Button onClick={() => this.setState({deleteModalOpen: true})} label="löschen" />}*/}
            <Button
              color="primary"
              onClick={this._save}
              disabled={savingDisabled}
            >
              {savingDisabled ? savingStatus : 'speichern'}
            </Button>
          </div>
        </ActionButtonRow>
      </div>
    );
  }
}
