import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import AddCircle from "@material-ui/icons/AddCircle";
import styled from 'styled-components';

const Clear = styled(AddCircle)`
  transform: rotate(45deg);
  font-size: 20px;
`;

const Wrapper = styled.div`
  display: inline-block;
`;

function renderInput(inputProps) {
  const { InputProps, ref, clearSelection, ...other } = inputProps;

  return (
    <TextField
      style={{ width: 300 }}
      InputProps={{
        inputRef: ref,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="Toggle password visibility" onClick={() => {
              clearSelection();
              if (ref.current) ref.current.focus();
            }}>
              <Clear />
            </IconButton>
          </InputAdornment>
        ),
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem, getLabel, getId }) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (getLabel(selectedItem) || '').indexOf(getLabel(suggestion)) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={getId(suggestion)}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {getLabel(suggestion)}
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.object.isRequired,
};

function getSuggestions(value, itemList, getLabel) {
  if (!value) return itemList;
  return itemList.filter(item => getLabel(item).toLowerCase().includes(value.toLowerCase()));
}

const adornmentWidth = 56;

export default function AutoComplete({availableItems, label, getId, getLabel, ...props}) {
  const ref = useRef(null);
  return (
    <Downshift itemToString={getLabel} {...props}>
      {({
          getRootProps,
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
          openMenu,
          clearSelection
        }) => (
        <Wrapper {...getRootProps()}>
          {renderInput({
            InputProps: getInputProps({
              onClick: openMenu,
              onFocus: openMenu
            }),
            ref,
            label,
            clearSelection
          })}
          <Popper open={isOpen} anchorEl={ref.current} placement="bottom-start">
            <div {...(isOpen ? getMenuProps({}, { suppressRefError: true }) : {})}>
              <Paper
                square
                style={{
                  maxHeight: 150,
                  overflowY: 'auto',
                  marginTop: 8,
                  minWidth: ref.current ? ref.current.clientWidth + adornmentWidth : null
                }}
              >
                {getSuggestions(inputValue, availableItems, getLabel).map((suggestion, index) =>
                  renderSuggestion({
                    suggestion,
                    index,
                    itemProps: getItemProps({ item: suggestion }),
                    highlightedIndex,
                    selectedItem,
                    getLabel,
                    getId
                  })
                )}
              </Paper>
            </div>
          </Popper>
        </Wrapper>
      )}
    </Downshift>
  );
}
