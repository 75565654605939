import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {HeadlineH1, HeadlineH2} from './text/Headlines';
import Selector from './Selector';
import ActionButtonRow from '../components/ActionButtonRow';
import filterOutNotDefinedValues from '../helpers/filterOutNotDefinedValues';
import ErrorMessage from '../components/ErrorMessage';
import convertErrors from '../helpers/errorHelper';
import ArchiveModal from './ArchiveModal';

export default class extends Component {
  constructor(props) {
    super(props);
    let {
      data: {block},
    } = props;
    this.state = {
      name: '',
      description: '',
      street: '',
      number: '',
      zipCode: '',
      city: '',
      archiveModalOpen: false,
      region: undefined,
      hidden: false,
      ...filterOutNotDefinedValues(block),
      savingStatus: this._isNew(props) ? undefined : 'Daten werden geladen...',
    };
  }

  componentWillReceiveProps = newProps => {
    if (newProps.data.error) {
      const {graphQLErrors} = newProps.data.error || {};
      this.setState({errors: graphQLErrors});
    } else {
      const {block} = newProps.data || {};
      this.setState(({savingStatus}) => ({
        errors: [],
        ...filterOutNotDefinedValues(block),
        savingStatus: block && block._id ? undefined : savingStatus,
      }));
    }
  };

  isNew = (props = this.props) => !props.match.params.id;

  _goBackToList = () => this.props.history.replace('/blocks');

  _renderHeadline = () => {
    if (this._isNew()) {
      return <HeadlineH1>Neue Wohnanlage</HeadlineH1>;
    }
    return <HeadlineH1>Wohnanlage bearbeiten</HeadlineH1>;
  };

  _updateRegion = region => {
    console.log(region);
    this.setState({region: region});
  };

  _isNew = (props = this.props) => !props.match.params.id;

  lastSave = undefined;
  _save = () => {
    const now = Date.now();
    if (this.lastSave && now - this.lastSave < 1000) return;
    this.lastSave = now;
    this.setState({savingStatus: 'Daten werden gespeichert...'});
    this.props
      .save({...filterOutNotDefinedValues(this.state), region: this.state.region && this.state.region._id})
      .then(() => {
        this.setState({savingStatus: undefined});
        this._goBackToList();
      })
      .catch(rawErrors => {
        let errors = convertErrors(rawErrors);
        this.setState({errors, savingStatus: undefined});
        console.log('there was an error sending the query', errors);
      });
  };

  render() {
    let {name, description, street, number, zipCode, city, region, errors, savingStatus} = this.state;
    let savingDisabled = !!savingStatus;
    return (
      <div>
        <ErrorMessage errors={errors} clearErrors={() => this.setState({errors: []})} />
        {this._renderHeadline()}
        <TextField
          type='text'
          fullWidth
          label='Name*'
          value={name}
          onChange={({target: {value}}) => this.setState({name: value})}
        />
        <TextField
          type='text'
          fullWidth
          label='Beschreibung'
          value={description}
          onChange={({target: {value}}) => this.setState({description: value})}
        />
        <TextField
          type='text'
          fullWidth
          label='Straße*'
          value={street}
          onChange={({target: {value}}) => this.setState({street: value})}
        />
        <TextField
          type='text'
          fullWidth
          label='Hausnummer*'
          value={number}
          onChange={({target: {value}}) => this.setState({number: value})}
        />
        <TextField
          type='text'
          fullWidth
          label='Ort*'
          value={city}
          onChange={({target: {value}}) => this.setState({city: value})}
        />
        <TextField
          type='text'
          fullWidth
          label='PLZ*'
          value={zipCode}
          onChange={({target: {value}}) => this.setState({zipCode: value})}
        />
        <HeadlineH2>Region</HeadlineH2>
        <Selector
          items={this.props.data.allRegions || []}
          selectedItems={region ? [region] : []}
          changeMethod={([region]) => this.setState({region})}
          renderItem={({name}) => name}
        />

        <ActionButtonRow>
          <Button onClick={this._goBackToList}>Zurück zur Übersicht</Button>
          {!this.state.hidden && (
            <div>
              {!this.isNew() && <Button onClick={() => this.setState({archiveModalOpen: true})}>archivieren</Button>}
              <Button color='primary' onClick={this._save} disabled={savingDisabled}>
                {savingDisabled ? savingStatus : 'speichern'}
              </Button>
            </div>
          )}
        </ActionButtonRow>
        <ArchiveModal
          open={this.state.archiveModalOpen}
          onCancel={() =>
            this.setState({
              archiveModalOpen: false,
            })
          }
          onConfirm={() => this.props.hide().then(this._goBackToList)}
        />
      </div>
    );
  }
}
