import gql from 'graphql-tag';
import {compose, graphql} from 'react-apollo';
import UserForm from '../components/UserForm';
import {SimpleBlock, User} from '../qql-shared/fragments';
import {
  DeleteUserMutation,
  DeleteUserMutationConfig,
  SaveUserMutation,
  SaveUserMutationConfig,
} from '../qql-shared/mutations';

const UserQuery = gql`
  query user($_id: String!) {
    user(_id: $_id) {
      ...User
    }
    allBlocks(hidden: false) {
      ...SimpleBlock
    }
  }
  ${User}
  ${SimpleBlock}
`;

const UserQueryConfig = {
  options: ({match}) => ({variables: {_id: match.params.id}}),
};

export default compose(
  graphql(SaveUserMutation, SaveUserMutationConfig),
  graphql(UserQuery, UserQueryConfig),
  graphql(DeleteUserMutation, DeleteUserMutationConfig)
)(UserForm);
