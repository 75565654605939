import { createMuiTheme } from '@material-ui/core/styles';
import constants from './parameters/constants';
export default createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: constants.primary2Color,
      main: constants.primaryColor,
      dark: constants.primaryColor,
      // contrastText: getContrastText(constants.primaryColor), // Todo: do we need that?
    },
    secondary: {
      light: constants.complementaryColor,
      main: constants.complementaryColor,
      dark: constants.complementaryColor,
      // contrastText: getContrastText(palette.secondary.A400), // Todo: do we need that?
    }
  },
  overrides: {
    MuiFormControl: {
      root: {
        marginTop: 3,
        marginBottom: 12
      }
    }
  }
});
