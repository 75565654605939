import React from 'react';
import EntityTable from './table/EntityTable';
import {Link} from 'react-router-dom';
import {NewButton, AdditionalButton} from './form/FormElements';
import {HeadlineH1} from './text/Headlines';

const _tableConfig = () => {
  return {
    maxRows: 25,
    cols: [
      {
        header: 'Name',
        valueKey: 'name',
        method: name => name,
        width: '25%',
      },
      {
        header: 'Region',
        valueKey: 'region',
        method: ({name}) => name,
        width: '25%',
      },
    ],
  };
};

export default ({data: {loading, allBlocks, refetch, variables: {hidden}} = {}, history}) => {
  return (
    <div>
      <HeadlineH1>Wohnanlagen ({hidden ? 'archiviert' : 'sichtbar'})</HeadlineH1>
      <NewButton>
        <Link to='/new/block'>Neue Wohnanlage</Link>
      </NewButton>
      <AdditionalButton
        onClick={() => refetch({hidden: !hidden})}
        style={{cursor: 'pointer', marginRight: '30px', color: 'white'}}>
        <div>{!hidden ? 'archiviert' : 'sichtbar'}</div>
      </AdditionalButton>
      <EntityTable
        tableConfig={_tableConfig()}
        data={allBlocks || []}
        msg={'Noch keine Wohnanlagen erstellt'}
        onRowClicked={row => history.push('/blocks/' + row._id)}
      />
    </div>
  );
};
