import {graphql} from 'react-apollo';
import gql from 'graphql-tag';
import Login from '../components/Login';

const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      _id
      role
      token
    }
  }
`;

const LOGIN_MUTATION_CONFIG = {
  props: ({mutate}) => ({
    login(variables) {
      return mutate({
        variables
      });
    }
  })
};

export default graphql(LOGIN_MUTATION, LOGIN_MUTATION_CONFIG)(Login);
