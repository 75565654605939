import BlockForm from '../components/BlockForm';
import {graphql} from 'react-apollo';
import {SimpleRegion} from '../qql-shared/fragments';
import {SaveBlockMutation, SaveBlockMutationConfig} from '../qql-shared/mutations';
import gql from 'graphql-tag';

const BlockQuery = gql`
  query allRegions {
    allRegions{
      ...SimpleRegion
    }
  }
  ${SimpleRegion}
`;

export default graphql(SaveBlockMutation, SaveBlockMutationConfig)(graphql(BlockQuery)(BlockForm));
