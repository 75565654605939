import BlockForm from '../components/BlockForm';
import {compose, graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {Block, SimpleRegion} from '../qql-shared/fragments';
import {SaveBlockMutation, SaveBlockMutationConfig} from '../qql-shared/mutations';

const BlockQuery = gql`
  query block($_id: String!) {
    block(_id: $_id) {
      ...Block
    }
    allRegions {
      ...SimpleRegion
    }
  }
  ${Block}
  ${SimpleRegion}
`;

const BlockQueryConfig = {
  options: ({match}) => ({variables: {_id: match.params.id}}),
};

export const HideMutation = gql`
  mutation hideBlock($_id: String!) {
    hideBlock(_id: $_id) {
      ...Block
    }
  }
  ${Block}
`;

export const HideMutationConfig = {
  props: ({
    mutate,
    ownProps: {
      match: {
        params: {id: _id},
      },
    },
  }) => ({
    hide: () => mutate({variables: {_id}}),
  }),
};

export default compose(
  graphql(SaveBlockMutation, SaveBlockMutationConfig),
  graphql(BlockQuery, BlockQueryConfig),
  graphql(HideMutation, HideMutationConfig)
)(BlockForm);
